/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import numeral from "numeral";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ClearIcon from "@mui/icons-material/Clear";
/*-----------------------Cac thu vien dung chung -------------------------*/

const formatNumbers = [
  "0,0.00",
  "0,0.0",
  "0,0.00",
  "0,0.000",
  "0,0.0000",
  "0,0.00000",
  "0,0.00000",
  "0,0.000000",
  "0,0.0000000",
  "0,0.00000000",
];

export default function PendingOrderControl(props) {
  var symbolPrice = props.marketPrices
    ? props.marketPrices[props.TradeOrder.symbol]
    : null;
  if (!symbolPrice) {
    symbolPrice = { digits: 3 };
  }
  return (
    <Paper variant="outlined"
    sx={{backgroundColor: "#d0d0e1"}}
    >
      <Grid container columns={9} spacing={1}>
        <Grid item xs={1}>
          <Avatar
            sx={{
              width: 24,
              height: 24,
              margin: 1,
              bgcolor: props.TradeOrder.type === 2 ? "green" : "red",
            }}
          >
            <Typography fontSize={11}>
              {props.TradeOrder.type === 2 ? "B" : "S"}
            </Typography>
          </Avatar>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="text"
            sx={{ color: "black" }}
            onClick={() => (props.doAction ? props.doAction("modify") : null)}
          >
            {props.TradeOrder.symbol}
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{props.TradeOrder.volume}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              [
              {numeral(props.TradeOrder.price).format(
                formatNumbers[symbolPrice.digits]
              )}
              ]
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title={numeral(props.TradeOrder.takeProfit).format(
              formatNumbers[symbolPrice.digits]
            )}
            arrow
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
                bgcolor: props.TradeOrder.takeProfit ? "blue" : "gray",
              }}
            >
              <Button sx={{ color: "white", fontWeight: "small" }}>TP</Button>
            </Avatar>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title={numeral(props.TradeOrder.stopLoss).format(
              formatNumbers[symbolPrice.digits]
            )}
            arrow
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
                bgcolor: props.TradeOrder.stopLoss ? "blue" : "gray",
              }}
            >
              <Button sx={{ color: "white", fontWeight: "small" }}>SL</Button>
            </Avatar>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => (props.doAction ? props.doAction("cancel") : null)}
          >
            <ClearIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

/**Hien thi gia thi truong */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import numeral from "numeral";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SymbolPriceControl from "./SymbolPriceControl";
import TerminalControl from "./TerminalControl";
import WatchListDialog from "./WatchListDialog";
import PlaceOrderDialog from "./PlaceOrderDialog";
import OrderModifyDialog from "./OrderModifyDialog";
import PartialCloseDialog from "./PartialCloseDialog";
import SimpleDialog from "../components/SimpleDialog";
import BotInstallDialog from "./BotInstallDialog";
import { DataGrid } from "@mui/x-data-grid";
import { Filter, Search } from "@mui/icons-material";
const server = require("../lib/server");
const myLib = require("../lib/MyLib");
const $ = window.$; //jquery
const column_style = {
  disableColumnMenu: true,
  disableReorder: true,
  headerAlign: "center",
  align: "center",
};
const orderTypes = ["Buy", "Sell", "Buy-Limit", "Sell-Limit"];

export class HistoryControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenSmall: window.screen.width < 500, //xac dinh man hinh phone hay may tinh
      DM_TaiKhoan: [], //danh sach tai khoan
      Filter: {
        TimeRange: 4, //to day
        AccountNumber: null,
      },
      paginationModel: {
        page: 0,
        pageSize: 50,
      },
      SearchResult: {
        DataList: [],
        DataCount: 0,
      },
      //hien thi dialog
    };
    this.columns = [
      {
        field: "Symbol",
        headerName: "Symbol",
        disableColumnMenu: true,
        width: 90,

        ...column_style,
        align: "center",
        valueGetter: ({ value, row }) =>
          value == "" ? (row.Profit > 0 ? "(Deposit)" : "(Withdraw)") : value,
      },
      {
        field: "CloseTime",
        headerName: "Time",
        width: 150,
        ...column_style,
        valueGetter: ({ value, row }) =>
          value ? moment(value).format("YYYY.MM.DD * HH:mm:ss") : null,
      },

      {
        field: "IsBuy",
        headerName: "Type",
        disableColumnMenu: true,
        width: 70,
        ...column_style,
        valueGetter: ({ value, row }) => (value ? "Buy" : "Sell"),
        renderCell: ({ value, row }) =>
          row.IsBuy !== undefined ? (
            row.IsBuy && row.Symbol !== "" ? (
              <span style={{ color: "blue" }}>{value}</span>
            ) : !row.IsBuy && row.Symbol !== "" ? (
              <span style={{ color: "red" }}>{value}</span>
            ) : null
          ) : null,
      },
      {
        field: "Volume",
        headerName: "Volume",
        disableColumnMenu: true,
        width: 75,
        ...column_style,
        headerAlign: "right",
        align: "right",
        valueGetter: ({ value, row }) =>
          row.Volume ? row.Volume.toFixed(2) : null,
      },
      {
        field: "ClosePrice",
        headerName: "Price",
        width: 95,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          value ? numeral(value).format("0,0.00") : null,
      },
      {
        field: "StopLoss",
        headerName: "S/L",
        width: 95,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          value ? numeral(value).format("0,0.00") : "",
      },
      {
        field: "TakeProfit",
        headerName: "T/P",
        width: 95,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          value ? numeral(value).format("0,0.00") : "",
      },
      {
        field: "Profit",
        headerName: "Profit",
        width: 95,
        ...column_style,
        headerAlign: "right",
        align: "right",
        renderCell: ({ value, row }) =>
          value >= 0 ? (
            <span style={{ color: "blue" }}>
              {numeral(value).format("0,0.00")}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {numeral(value).format("0,0.00")}
            </span>
          ),
      },
      {
        field: "Comment",
        headerName: "Comment",
        ...column_style,
        width: 200,
      },
    ];
  }
  componentDidMount() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_TaiKhoan_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        var sel = localStorage.getItem("SelectedAccount");
        var found = response.DM_TradingAccounts.find(
          (item) => item.AccountNumber == sel
        );
        this.setState(
          {
            DM_TaiKhoan: response.DM_TradingAccounts, //danh sach tai khoan mt5 cua user
            Filter: {
              ...this.state.Filter,
              AccountNumber: response.DM_TradingAccounts.length
                ? found
                  ? found.AccountNumber
                  : response.DM_TradingAccounts[0].AccountNumber
                : null,
            },
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        showError(error);
      });
  }
  doSearch() {
    this.setState({
      loading: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_FX_Transactions_Search",
        ThamSo: {
          PageIndex: this.state.paginationModel.page + 1,
          PageSize: this.state.paginationModel.pageSize,
          ...this.state.Filter,
        },
      })
      .then((response) => {
        //chen dong tong so len dau
        let sum = response.DataCount[0];
        response.DataList.splice(0, 0, {
          Symbol: "(SUM)",
          StopLoss: sum.TotalLost,
          TakeProfit: sum.TotalProfit,
          Profit: sum.TotalLost + sum.TotalProfit,
          Comment:
            "Cash: " +
            numeral(sum.TotalDeposit).format("0,0") +
            " / (" +
            numeral(sum.TotalWithdraw).format("0,0") + ")",
        });
        response.DataList.map((item, index) => (item.id = index));

        this.setState({
          loading: false,
          SearchResult: {
            DataList: response.DataList,
            DataCount: response.DataCount[0].Total,
          },
        });
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({
          loading: false,
          SearchResult: {
            DataList: [],
            DataCount: 0,
          },
        });
      });
  }
  componentWillUnmount() {}
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate() {
    return !this.showBotInstall;
  }
  componentDidUpdate() {}

  render() {
    return (
      <Grid container columns={12} spacing={0.5}>
        <Grid item xs={12}>
          .
        </Grid>
        <Grid item xs={1}>
          <FormLabel>Account:</FormLabel>
        </Grid>
        <Grid item xs={2}>
          <Select
            fullWidth
            value={this.state.Filter.AccountNumber}
            onChange={(event) => {
              this.state.Filter.AccountNumber = event.target.value;
              this.state.paginationModel.page = 0;
              this.doSearch();
            }}
          >
            {this.state.DM_TaiKhoan.map((item, index) => (
              <MenuItem value={item.AccountNumber}>
                {index + 1 + ". " + item.AccountName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <RadioGroup
            row
            value={this.state.Filter.TimeRange}
            onChange={(event) => {
              this.state.Filter.TimeRange = event.target.value;
              this.state.paginationModel.page = 0;
              this.doSearch();
            }}
          >
            <FormControlLabel
              value="1"
              control={<Radio size="12" />}
              label="Today"
            />
            <FormControlLabel
              value="2"
              control={<Radio size="12" />}
              label="3 days"
            />
            <FormControlLabel
              value="3"
              control={<Radio size="12" />}
              label="This week"
            />
            <FormControlLabel
              value="4"
              control={<Radio size="12" />}
              label="This month"
            />
            <FormControlLabel
              value="100"
              control={<Radio size="12" />}
              label="All"
            />
            <FormControlLabel
              value="99"
              control={<Radio size="12" />}
              label="Balance"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth variant="contained" onClick={() => this.doSearch()}>
            Search
          </Button>
        </Grid>
        <Grid item xs={1}>
          {this.state.loading ? (
            <CircularProgress size={24} color="primary" />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              height: "calc(100vh - 144px)",
              backgroundColor: "lightgray",
            }}
          >
            <DataGrid
              rows={this.state.SearchResult.DataList}
              rowCount={this.state.SearchResult.DataCount}
              pageSizeOptions={[25, 50, 100]}
              paginationMode="server"
              paginationModel={this.state.paginationModel}
              onPaginationModelChange={(model) =>
                this.setState({ paginationModel: model }, () => this.doSearch())
              }
              columns={this.columns}
              columnHeaderHeight={28}
              rowHeight={32}
              showCellVerticalBorder
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles({}, { withTheme: true })(HistoryControl);

'use strict';

import ProvisioningProfile from './provisioningProfile';
import { ProvisioningProfilesList } from './provisioningProfileApi';

/**
 * Exposes provisioning profile API logic to the consumers
 */
export default class ProvisioningProfileApi {

  /**
   * Constructs a provisioning profile API instance
   * @param {ProvisioningProfileClient} provisioningProfileClient provisioning profile REST API client
   */
  constructor(provisioningProfileClient) {
    this._provisioningProfileClient = provisioningProfileClient;
  }

  /**
   * Retrieves provisioning profiles, provides pagination in infinite scroll style
   * @param {ProvisioningProfilesFilter} [filter] provisioning profiles filter
   * @return {Promise<Array<ProvisioningProfile>>} promise resolving with an 
   * array of provisioning profile entities
   */
  async getProvisioningProfilesWithInfiniteScrollPagination(filter) {
    let profiles = await this._provisioningProfileClient.getProvisioningProfiles(filter, '1');
    return profiles.map(p => new ProvisioningProfile(p, this._provisioningProfileClient));
  }

  /**
   * Retrieves provisioning profiles and count, provides pagination in a classic style
   * @param {ProvisioningProfilesFilter} [filter] provisioning profiles filter
   * @return {Promise<ProvisioningProfilesList>} promise resolving with provisioning profile entities and count
   */
  async getProvisioningProfilesWithClassicPagination(filter) {
    let profiles = await this._provisioningProfileClient.getProvisioningProfiles(filter, '2');
    return {
      count: profiles.count,
      items: profiles.items.map(p => new ProvisioningProfile(p, this._provisioningProfileClient))
    };
  }

  /**
   * Retrieves a provisioning profile by id
   * @param {String} provisioningProfileId provisioning profile id
   * @return {Promise<ProvisioningProfile>} promise resolving with provisioning profile entity
   */
  async getProvisioningProfile(provisioningProfileId) {
    let profile = await this._provisioningProfileClient.getProvisioningProfile(provisioningProfileId);
    return new ProvisioningProfile(profile, this._provisioningProfileClient);
  }

  /**
   * Creates a provisioning profile
   * @param {NewProvisioningProfileDto} profile provisioning profile data
   * @return {Promise<ProvisioningProfile>} promise resolving with provisioning profile entity
   */
  async createProvisioningProfile(profile) {
    let id = await this._provisioningProfileClient.createProvisioningProfile(profile);
    return new ProvisioningProfile(Object.assign({}, profile, {_id: id.id, status: 'new'}),
      this._provisioningProfileClient);
  }

}

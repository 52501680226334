/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountActionMenu from "../components/AccountActionMenu";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SymbolSelectDialog from "./SymbolSelectDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
var numeral = require("numeral");

const listActions = [
  {
    ID: "PlaceOrder",
    Label: "Place Order",
    Icon: <CurrencyExchangeIcon color="error" />,
  },
  {
    ID: "Divider1",
    Label: "-",
  },
  {
    ID: "Symbols",
    Label: "List of symbols",
    Icon: <PriceChangeIcon />,
  },
];

function AccountStateControlPure(props) {
  //show form
  const equityRef = React.useRef(null); //tro vao equity
  let account = props.Account;
  let metaAccount = props.metaAccount;
  //cac du lieu lien quan cua tai khoan
  let accountEquity = metaAccount.equity
    ? "E: " + numeral(metaAccount.equity).format("0,0.00") + " $"
    : "E: ...$";
  const [lastAccountEquity, setLastAccountEquity] = React.useState(
    metaAccount.equity
  );
  const [equityChanged, setEquityChanged] = React.useState(false);
  if (metaAccount.equity != lastAccountEquity) {
    if (equityRef && equityRef.current) {
      equityRef.current.style.backgroundColor =
        metaAccount.equity < lastAccountEquity ? "#ffc2b3" : "#66ccff";
      window.setTimeout(() => {
        if (equityRef.current) {
          equityRef.current.style.backgroundColor = "";
        }
      }, 500);
    }
    setLastAccountEquity(metaAccount.equity);
  }
  let accountBalance = metaAccount.balance
    ? "B: " + numeral(metaAccount.balance).format("0,0.00") + " $"
    : "B: ... $";

  let accountLeverage = metaAccount.leverage
    ? "L: 1/" + metaAccount.leverage
    : "L: ... ";
  //console.log(props.isConnected, props.isConnecting);
  //console.log(account);
  return (
    <Paper variant="outlined">
      <Grid container columns={12}>
        <Grid item xs={1}>
          {props.isConnecting ? (
            <CircularProgress size={24} />
          ) : (
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
              }}
            >
              {props.isConnected ? (
                <PlayCircleIcon color="primary" />
              ) : (
                <StopCircleIcon color="error" />
              )}
            </Avatar>
          )}
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            startIcon={
              account.AccountType === "V1" ? (
                <LooksOneIcon color="success" fontSize="small" />
              ) : account.AccountType == "V2" ? (
                <LooksTwoIcon color="success" fontSize="small" />
              ) : account.AccountType == "D" ? (
                <SportsMartialArtsIcon color="success" />
              ) : account.AccountType == "C" ? (
                <MonetizationOnIcon color="primary" fontSize="small" />
              ) : (
                <RequestQuoteIcon color="primary" fontSize="small" />
              )
            }
            sx={{
              color: props.BrokerConnected ? "blue" : "red",
            }}
          >
            {account.AccountName}
          </Button>
        </Grid>

        <Grid item xs={2}>
          <Button variant="text" ref={equityRef}>
            {accountEquity}
          </Button>
        </Grid>

        <Grid item xs={2}>
          <Button variant="text">{accountBalance}</Button>
        </Grid>

        <Grid item xs={2}>
          <Button variant="text">{accountLeverage}</Button>
        </Grid>

        <Grid item xs={1}>
          <AccountActionMenu
            disabled={!props.BrokerConnected}
            SubActions={listActions}
            onClick={(button) => {
              props.onAction(button);
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
export default withStyles({}, { withTheme: true })(AccountStateControlPure);

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import GenerateCodeReactDialog from "../tools/GenerateCodeReactDialog";
import SimpleDialog from "../components/SimpleDialog";

import {
  filterStyles,
  loadDataError,
  handleServerError,
  loadGridColumns,
  ReactDataGrid_i18n,
} from "../components/common";
import moment from "moment";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

/*-----------------------Cac dinh nghia ----------------------------------*/

const default_gridColumns = [
  {
    name: "symbol",
    type: "string",
    header: "Symbol",
    headerAlign: "center",
    defaultWidth: 140,
  },
  {
    name: "description",
    header: "Name",
    headerAlign: "center",
    defaultWidth: 400,
  },
];
//neu co dinh nghia trong global thi lay ra, khong thi lay default

const defaultSortInfo = {
  id: "symbol",
  name: "symbol",
  dir: 1,
  type: "string",
};
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};
////////////////////////////////////////////////////////////////////////////
export default class SymbolSelectDialog extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click

  constructor(props) {
    super(props);
    let symbols = props.BrokerSymbols; //danh sach symbol
    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: symbols, count: symbols.length }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong

      DanhMuc: {},
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo,
      after: {
        PageIndex: 1,
        PageSize: 20,
        Ascending: true,
        SortBy: getSortColumnIndex(default_gridColumns, defaultSortInfo), //index column duoc sap xep
        //tieu chi tim kiem
        Symbol: "",
      },
      rowSelected: undefined, //dang lua chon row nao
      showEditDialog: false, // hien thi form cap nhat hay khong
      edittingRecord: undefined, //ban ghi chon de edit
      showConfirmXoa: false, //hien thi dialog xoa
      hasQuyen: global.hasRoles("R121"),
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }

  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "Symbol"
    ) {
      this.setState(this.state);
    } else {
      //neu lien quan den phan trang, sap xep thi load lai du lieu tu server
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
    this.setState(this.state);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  loadDanhMuc() {}
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      var symbol = this.state.after.Symbol; //tieu chi loc
      let list = this.props.BrokerSymbols.filter((item) => {
        return (
          item.symbol.toUpperCase().indexOf(symbol) >= 0 ||
          item.description.toUpperCase().indexOf(symbol) >= 0
        );
      });
      console.log(list);
      resolve({ data: list, count: list.count });
    });
    return loader;
  };
  doSearch = () => {
    var symbol = this.state.after.Symbol; //tieu chi loc
    let list = this.props.BrokerSymbols.filter((item) => {
      return (
        item.symbol.toUpperCase().indexOf(symbol) >= 0 ||
        item.description.toUpperCase().indexOf(symbol) >= 0
      );
    });
    this.setState({
      search_result: { data: list, count: list.count },
      search_dataSource: list,
    });
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative", marginBottom: 1 }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                List of symbols
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.hasQuyen ? (
                  <>
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => {
                        this.setState({ showEditDialog: true });
                      }}
                    >
                      Thêm
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      disabled={this.state.rowSelected === undefined}
                      onClick={() => {
                        this.setState({
                          showEditDialog: true,
                          edittingRecord: this.state.rowSelected,
                        });
                      }}
                    >
                      Sửa
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      disabled={this.state.rowSelected === undefined}
                      onClick={() => {
                        this.setState({ showConfirmXoa: true });
                      }}
                    >
                      Xóa
                    </Button>
                  </>
                ) : null}

                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12}>
              <Grid item xs={3}>
                <TextField
                  id="symbol"
                  fullWidth
                  autoFocus
                  label="Symbol"
                  autoComplete="off"
                  value={this.state.after.Symbol}
                  onChange={(event) =>
                    this.saveField("Symbol", event.target.value.toUpperCase())
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonTimKiem
                  variant="contained"
                  startIcon={<SearchIcon fontSize="small" />}
                  title="Search"
                  onClick={this.doSearch}
                  onRightClickMenu={(item) => {
                    if (item.menuName == "CodeSearch") {
                      this.setState({ open_code_seach: true });
                    }
                    if (item.menuName == "Columns") {
                      this.setState({ open_columns_define: true });
                    }
                    if (item.menuName == "Generate") {
                      this.setState({ open_generate_code: true });
                    }
                    if (item.menuName == "GenerateJs") {
                      this.setState({ open_generate_react: true });
                    }
                  }}
                ></ButtonTimKiem>
              </Grid>
            </Grid>
          </Paper>
          <ReactDataGrid
            licenseKey="1"
            style={{ height: 450, fontSize: 12 }}
            showZebraRows={true}
            columns={default_gridColumns}
            pagination={true}
            multiSelect={false}
            showHoverRows={false}
            checkboxColumn={{
              render: (props) => (
                <input
                  type="checkbox"
                  readOnly
                  checked={props.rowSelected}
                ></input>
              ),
            }}
            defaultLimit={10}
            limit={this.state.after.PageSize}
            skip={(this.state.after.PageIndex - 1) * this.state.after.PageSize}
            onSkipChange={(skip) => {
              this.saveField("PageIndex", skip / this.state.after.PageSize + 1);
            }}
            dataSource={this.state.search_dataSource}
            idProperty="symbol"
            headerHeight={30}
            rowHeight={30}
            allowUnsort={false}
            defaultSortInfo={defaultSortInfo}
            sortInfo={this.state.sortInfo}
            onSortInfoChange={this.onSortInfoChange}
            onLimitChange={(v) => this.saveField("PageSize", v)}
            emptyText={loadDataError(
              this.state.search_error,
              this.state.search_result.data.length === 0,
              this.state.errorMessage,
              this.state.errorLog
            )}
            skipLoadOnMount={true}
            enableSelection={true}
            onSelectionChange={({ data, selected, unselected }) => {
              var now = new Date().getTime();
              var diff = now - this.lastClick;
              if (diff > 300) {
                this.setState({ rowSelected: data });
              } else {
                //double click
                this.setState(
                  {
                    rowSelected: data,
                  },
                  () => {
                    if (!this.props.disableChon) {
                      this.props.close(
                        this.state.rowSelected,
                        this.state.search_result.data
                      );
                    }
                  }
                );
              }
              this.lastClick = now;
            }}
          ></ReactDataGrid>
        </DialogContent>
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={default_gridColumns}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={default_gridColumns}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_gridColumns,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_react ? (
          <GenerateCodeReactDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_react: false,
              });
            }}
          />
        ) : null}

        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa:" + this.state.rowSelected.ShipID + " ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DM_Ship_Delete",
                      ThamSo: this.state.rowSelected,
                    })
                    .then((response) => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}

import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ApiIcon from "@mui/icons-material/Api";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import InsightsIcon from "@mui/icons-material/Insights";

export function MainListItems(props) {
  return (
    <React.Fragment>
      <ListItemButton
        selected={props.activePage === "Trading"}
        onClick={() => props.onClick("Trading")}
      >
        <ListItemIcon>
          <InsightsIcon
            color={props.activePage === "Trading" ? "primary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Trading" />
      </ListItemButton>
      <ListItemButton
        onClick={() => props.onClick("Orders")}
        selected={props.activePage === "Orders"}
      >
        <ListItemIcon>
          <ShoppingCartIcon
            color={props.activePage === "Orders" ? "primary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton>
    </React.Fragment>
  );
}

export function SecondaryListItems(props) {
  return (
    <React.Fragment>
      {props.showGroup ? (
        <ListSubheader component="div" inset>
          Admins
        </ListSubheader>
      ) : null}
      <ListItemButton onClick={() => props.onClick("MetaCloud")}>
        <ListItemIcon>
          <ApiIcon
            color={props.activePage === "MetaCloud" ? "primary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="MetaCloud" />
      </ListItemButton>
      <ListItemButton onClick={() => props.onClick("Accounts")}>
        <ListItemIcon>
          <RecentActorsIcon
            color={props.activePage === "Accounts" ? "primary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Accounts" />
      </ListItemButton>
    </React.Fragment>
  );
}

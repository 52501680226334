/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "white",
  },
  menuIcon: {
    color: "white",
  },
}));

export default function BotsMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <AddIcon color="primary" fontSize="10px" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.SubActions.map((button, index) =>
          button.Label === "-" ? (
            <Divider key={"K" + index} />
          ) : (
            <MenuItem
              key={button.ID}
              onClick={() => {
                if (props.onClick) {
                  props.onClick(button);
                }
                handleClose();
              }}
            >
              <ListItemIcon>{button.Icon}</ListItemIcon>
              <ListItemText>{button.Label}</ListItemText>
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React, { version } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import MyNumberEditor from "../components/MyNumberEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
var numeral = require("numeral");

///Tra ve text chua tham so theo quy dinh cua MT5

/*input string BotName = "DCA"; //Ten BOT
input string BuyOrSell = "B"; //Vao lenh
input double GiaLenhL1 = 1; // Gia vao lenh L1
input int SoLenhToiDa = 10; //So lenh toi da
input string KhoiLuong = "L1:0.01/L2:0.02/L3:0.03/L4:0.04/L5:0.05/L6:0.06/L7:0.07/L8:0.08/L9:0.09/L10:0.1"; //Khoi luong lenh
input string BienDoGia = "L2:5/L3:5/L4:5/L5:5/L6:5/L7:5/L8:5/L9:5/L10:5/L11:5/L12:5/L13:5/L14:5/L15:5/L16:5"; //Bien do gia
input string BienDoTP = "L1:5/L2:5/L3:5/L4:5/L5:5/L6:5/L7:5/L8:5/L9:5/L10:5/L11:5/L12:5/L13:5/L14:5/L15:5/L16:5"; //Bien do TP
input string DongLenh = "L1:1/L2:2/L3:3/L4:4/L5:5/L6:6/L7:7/L8:8/L9:9/L10:10/L11:11/L12:12/L13:13/L14:14/L15:15/L16:16"; //Vi tri close toan bo:
input double KhoangGia_SL = 55; //Khoang gia SL

string thamso = StringFormat("BuyOrSell=%s;GiaLenhL1=%f;KhoiLuong=%s;BienDoGia=%s;BienDoTP=%s;SoLenhToiDa=%d;DongLenh=%s;KhoangGia_SL=%f", 
			BuyOrSell, GiaLenhL1, KhoiLuong, BienDoGia, BienDoTP, SoLenhToiDa, DongLenh, KhoangGia_SL);
//luu y BienDoGia ko bao gom L1
*/
export function getThamSo_MT5_DCA1(state) {
  var khoiluong = "";
  state.KhoiLuong.map(
    (value, index) =>
      (khoiluong +=
        (khoiluong == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  var biendoGia = "";
  state.BienDoGia.map((value, index) => {
    biendoGia +=
      (biendoGia == "" ? "" : "/") +
      "L" +
      (index + 1) +
      ":" +
      (value == null || value == "null" ? 0 : value);
  });

  var biendoTP = "";
  state.BienDoTP.map(
    (value, index) =>
      (biendoTP +=
        (biendoTP == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  var dongLenh = "";
  state.DongLenh.map(
    (value, index) =>
      (dongLenh +=
        (dongLenh == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  return (
    "BotCode=" +
    state.BotCode +
    ";BuyOrSell=" +
    state.VaoLenh +
    ";GiaLenhL1=" +
    state.GiaLenhL1 +
    ";SoLenhToiDa=" +
    state.SoLenhToiDa +
    ";KhoiLuong=" +
    khoiluong +
    ";BienDoGia=" +
    biendoGia +
    ";BienDoTP=" +
    biendoTP +
    ";DongLenh=" +
    dongLenh +
    ";KhoangGia_SL=" +
    state.KhoangGia_SL
  );
}
//lay danh sach so' tu string cac lenh L1, L2
function getThamSo_Array(str) {
  var ret = [];
  var list = str.split("/");
  list.map((item) => {
    ret.push(parseFloat(item.split(":")[1]));
  });
  while(ret.length < 18) {
    ret.push(0);
  }
  return ret;
}
///Chuyen tu tham so string thanh cau truc state
export function extractFrom_MT5_DCA1(thamso) {
  var state = {};
  var list = thamso.split(";");
  list.map((item) => {
    if (item != "") {
      var values = item.split("=");
      if (values[0] == "BotCode") {
        state.BotCode = values[1];
      } else if (values[0] == "BuyOrSell") {
        state.VaoLenh = values[1];
      } else if (values[0] == "GiaLenhL1") {
        state.GiaLenhL1 = values[1];
      } else if (values[0] == "SoLenhToiDa") {
        state.SoLenhToiDa = parseInt(values[1]);
      } else if (values[0] == "KhoangGia_SL") {
        state.KhoangGia_SL = parseFloat(values[1]);
      } else if (values[0] == "KhoiLuong") {
        state.KhoiLuong = getThamSo_Array(values[1]);
      } else if (values[0] == "BienDoGia") {
        state.BienDoGia = getThamSo_Array(values[1]); //do ko bao gom L1
      } else if (values[0] == "BienDoTP") {
        state.BienDoTP = getThamSo_Array(values[1]);
      } else if (values[0] == "DongLenh") {
        state.DongLenh = getThamSo_Array(values[1]);
      }
    }
  });
  return state;
}
export function validate_ThamSo_DCA1(bot) {
  var config = bot.ConfigData;
  if (myLib.isEmpty(bot.BotCode) || myLib.isEmpty(bot.BotName)) {
    return false;
  }
  if (config.GiaLenhL1 <= 0) {
    return false;
  }
  if (config.KhoangGia_SL <= 0) {
    return false;
  }
  if (config.SoLenhToiDa <= 0) {
    return false;
  }
  //khoi luong
  var ret = true;
  config.KhoiLuong.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do gia
  config.BienDoGia.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do tp
  config.BienDoTP.map((item, index) => {
    if (index < config.SoLenhToiDa && item <= 0) {
      ret = false;
    }
  });
  //dong lenh
  config.DongLenh.map((item, index) => {
    if (index < config.SoLenhToiDa && (item < 0 || item > index + 1)) {
      ret = false;
    }
  });

  return ret;
}

export class BotEditControl_DCA1 extends React.Component {
  constructor(props) {
    super(props);
    let values = props.BotData;
    this.state = {
      version: 0,
      BotCode: values.BotCode,
      BotName: values.BotName,
      BotMoTa: values.BotMoTa,
      VaoLenh: "B",
      GiaLenhL1: 0,
      SoLenhToiDa: 16,
      Active: true,
      KhoiLuong: [
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
      ],
      BienDoGia: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      BienDoTP: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      DongLenh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      KhoangGia_SL: 0,
      ...extractFrom_MT5_DCA1(values.BotThamSo ?? ""),
    };
  }
  setMyState(state) {
    //bao cho thang cha biet
    this.state.version = state.version = this.state.version + 1; //tang version
    this.setState(state, () =>
      this.props.onChange ? this.props.onChange(this.state) : 0
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.BotData.version > prevState.version) {
      var values = nextProps.BotData;
      var ret = {
        version: values.version,
        Active: values.Active == undefined ? prevState.Active : values.Active,
        ...(values.ConfigData ?? extractFrom_MT5_DCA1(values.BotThamSo ?? "")),
        BotCode: values.BotCode,
        BotName: values.BotName,
        BotMoTa: values.BotMoTa,
      };
      return ret;
    }
    return null;
  }
  componentDidUpdate() {}
  render() {
    return (
      <Paper variant="outlined" sx={{}}>
        <Grid container spacing={1} columns={6} padding={1}>
          <Grid item xs={1}>
            <FormLabel>Mã/Tên:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <OutlinedInput
              fullWidth
              value={this.state.BotCode}
              inputProps={{
                maxLength: 6,
              }}
              onChange={(event) => {
                this.setMyState({
                  BotCode: myLib.toUpperCase(event.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.BotName}
              onChange={(event) =>
                this.setMyState({
                  BotName: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Mô tả:</FormLabel>
          </Grid>
          <Grid item xs={5}>
            <OutlinedInput
              fullWidth
              multiline
              value={this.state.BotMoTa}
              onChange={(event) =>
                this.setMyState({
                  BotMoTa: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <strong>Tham số:</strong>
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Vào lệnh:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="contained"
              color={this.state.VaoLenh == "B" ? "primary" : "error"}
              onClick={(event) =>
                this.setMyState({
                  VaoLenh: this.state.VaoLenh == "B" ? "S" : "B",
                })
              }
            >
              {this.state.VaoLenh}
            </Button>
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Mức giá:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <MyNumberEditor
              decimalScale={2}
              color="blue"
              value={this.state.GiaLenhL1}
              onValueChange={(value) =>
                this.setMyState({
                  GiaLenhL1: value,
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Số lệnh:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <MyNumberEditor
              decimalScale={0}
              color="blue"
              value={this.state.SoLenhToiDa}
              onValueChange={(value) => {
                this.setMyState({
                  SoLenhToiDa: value,
                });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Cắt lỗ:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <MyNumberEditor
              decimalScale={2}
              color="blue"
              value={this.state.KhoangGia_SL}
              onValueChange={(value) =>
                this.setMyState({
                  KhoangGia_SL: value,
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color={this.state.Active ? "primary" : "error"}
              onClick={(event) =>
                this.setMyState({
                  Active: this.state.Active ? false : true,
                })
              }
            >
              {this.state.Active ? "Đang Chạy" : "Đang ngừng"}
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={6}>
            <hr />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <strong>Khối lượng</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Biên độ giá</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Biên độ TP</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Đóng lệnh</strong>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            {this.state.KhoiLuong.map((value, index) => (
              <OutlinedInput
                inputProps={{ style: { textAlign: "right" } }}
                readOnly
                value={"L" + (index + 1) + " => "}
              ></OutlinedInput>
            ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.KhoiLuong.map((value, index) => (
              <MyNumberEditor
                decimalScale={2}
                color="blue"
                value={this.state.KhoiLuong[index]}
                onValueChange={(value) => {
                  this.state.KhoiLuong[index] = value;
                  this.setMyState({
                    changed: new Date(),
                  });
                }}
              />
            ))}
          </Grid>
          <Grid item xs={1}>
            <OutlinedInput disabled={true} />
            {this.state.BienDoGia.map((value, index) => (
              <MyNumberEditor
                decimalScale={2}
                color="blue"
                value={this.state.BienDoGia[index]}
                onValueChange={(value) => {
                  this.state.BienDoGia[index] = value;
                  this.setMyState({
                    changed: new Date(),
                  });
                }}
              />
            ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.BienDoTP.map((value, index) => (
              <MyNumberEditor
                decimalScale={2}
                color="blue"
                value={this.state.BienDoTP[index]}
                onValueChange={(value) => {
                  this.state.BienDoTP[index] = value;
                  this.setMyState({
                    changed: new Date(),
                  });
                }}
              />
            ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.DongLenh.map((value, index) => (
              <MyNumberEditor
                decimalScale={0}
                color="blue"
                value={this.state.DongLenh[index]}
                onValueChange={(value) => {
                  this.state.DongLenh[index] = value;
                  this.setMyState({
                    changed: new Date(),
                  });
                }}
              />
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles({}, { withTheme: true })(BotEditControl_DCA1);

/**Nhap danh muc DinhBien */
import React from "react";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
  Menu,
  Link,
  ListItemText,
  IconButton,
  Paper,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode  from "jwt-decode";

import {
  Block,
  Help,
  Label,
  StayPrimaryLandscapeOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";
import { gapi } from "gapi-script";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

const google_clientID =
  "1090348261865-fi9q5vogptbmh9e602ac0r6sdh0s7i2h.apps.googleusercontent.com";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
//phai co doan code nay khong la loi~
window.gapi.load("client:auth2", () => {
  window.gapi.client.init({
    clientId: google_clientID,
    plugin_name: "chat",
  });
});

function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate(data) {
  var ret = validate_Empty(data, ["UserName", "Password"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export default function LoginControl(props) {
  const [state, setState] = useState({
    validateStatus: { error: false }, //trang thai validate
    danhmuc_coquan: [],
    after: {
      UserName: localStorage.getItem("login_name"),
      Password: "",
      SaveSession: true,
    },
    showConfirmExit: false, //co hien thi xac nhan thoat khong
    showErrorMessage: false,
    showErrorLog: false,
  });
  useEffect(() => {
    //do nothing
  });
  function doSave(googleResponse) {
    if (true) {
      let data = jwtDecode(googleResponse.credential);
      state.saving = true;
      state.errorLog = "";
      setState(state);
      var acc = {
        UserName: data.email,
        Password: googleResponse.credential,
      };
      server
        .postNoneBearer("User/Login", acc)
        .then((response) => {
          global.User = response;
          server.saveToken(response.JWT, true);
          document.cookie = "refresh_token_client=" + response.JWT;
          props.close(true);
        })
        .catch((error) => {
          state.saving = false;
          setState(state);
          handleServerError(error);
        });
    }
  }
  return (
    <GoogleOAuthProvider clientId={google_clientID}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 240,
        }}
      >
        <Grid container spacing={3} columns={24}>
          <Grid item xs={24}>
            <center>
              <GoogleLogin
                buttonText="Login by Google"
                onSuccess={(response) => {
                  doSave(response);
                }}
                onFailure={(response) => {
                  console.log(response);
                }}
              />
            </center>
          </Grid>
        </Grid>
      </Paper>

      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </GoogleOAuthProvider>
  );
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React, { version } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import MyNumberEditor from "../components/MyNumberEditor";
import CheDoMenu from "./CheDoMenu";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
var numeral = require("numeral");

const Menu_CheDo = [
  {
    ID: 1,
    Label: "Vùng trên",
    Icon: <LooksOneIcon color="primary" />,
  },
  {
    ID: 2,
    Label: "Vùng giữa",
    Icon: <LooksTwoIcon color="primary" />,
  },
  {
    ID: 3,
    Label: "Vùng dưới",
    Icon: <Looks3Icon color="primary" />,
  },
  {
    ID: 4,
    Label: "Trên hoặc dưới",
    Icon: <Looks4Icon color="primary" />,
  },
  {
    ID: 5,
    Label: "Open D1",
    Icon: <Looks5Icon color="primary" />,
  },
];

///Tra ve text chua tham so theo quy dinh cua MT5

/*
input string BotName = "HGV1"; //Ten BOT
input group "Vao lenh"
input int CheDoDanh = 0; //Vung danh':1-Tren/2-Giua/3-Duoi/4-Tren+Duoi/5-Theo gia Open-D1
input double VungTren = 0; //Vung gia tren
input double VungDuoi = 0; //Vung gia duoi:
input double BienDoGia_D1 = 0; //Bien do Open-D1:
input group "Close hedging"
input double BienDoClose_1 = 0; //Bien do Close - Vung 1:
input double BienDoClose_2 = 0; //Bien do Close Vung 2:
input double BienDoClose_3 = 0; //Bien do Close Vung 3:
input double BienDoClose_D1 = 0; //Bien do Close Open-D1:
input group "BUY"
input int Buy_SoLenhToiDa  = 9; //So luong lenh Buy toi da:
input string Buy_KhoiLuong = "L1:0.01/L2:0.02/L3:0.03/L4:0.04/L5:0.05/L6:0.06/L7:0.07/L8:0.08/L9:0.09/L10:0.01/L11:0.11/L12:0.12/L13:0.13/L14:0.14/L15:0.15/L16:0.16"; //Khoi luong lenh Buy:
input string Buy_BienDoGia = "L2:3/L3:3/L4:3/L5:3/L6:3/L7:3/L8:3/L9:3/L10:3/L11:3/L12:3/L13:3/L14:3/L15:3/L16:3"; //Bien do gia gia Buy:
input string Buy_BienDoTP = "L1:5/L2:5/L3:5/L4:3/L5:3/L6:3/L7:3/L8:3/L9:3/L10:3/L11:3/L12:3/L13:3/L14:3/L15:3/L16:3"; //Bien do TP lenh Buy:
input string Buy_DongLenh = "L1:0/L2:0/L3:0/L4:0/L5:5/L6:6/L7:6/L8:7/L9:8/L10:9/L11:8/L12:9/L13:11/L14:12/L15:13/L16:14"; //Vi tri close toan bo:
input double Buy_KhoangGia_SL = 55; //Bien do SL tinh tu L1:
input group "SELL"
input int Sell_SoLenhToiDa  = 9; //So luong lenh Sell toi da:
input string Sell_KhoiLuong = "L1:0.01/L2:0.02/L3:0.03/L4:0.04/L5:0.05/L6:0.06/L7:0.07/L8:0.08/L9:0.09/L10:0.01/L11:0.11/L12:0.12/L13:0.13/L14:0.14/L15:0.15/L16:0.16"; //Khoi luong lenh Sell:
input string Sell_BienDoGia = "L2:3/L3:3/L4:3/L5:3/L6:3/L7:3/L8:3/L9:3/L10:3/L11:3/L12:3/L13:3/L14:3/L15:3/L16:3"; //Bien do gia lenh Sell:
input string Sell_BienDoTP = "L1:5/L2:5/L3:5/L4:3/L5:3/L6:3/L7:3/L8:3/L9:3/L10:3/L11:3/L12:3/L13:3/L14:3/L15:3/L16:3"; //Bien do TP lenh Sell:
input string Sell_DongLenh = "L1:0/L2:0/L3:0/L4:0/L5:5/L6:6/L7:6/L8:7/L9:8/L10:9/L11:8/L12:9/L13:11/L14:12/L15:13/L16:14"; //Vi tri close toan bo:
input int Sell_KhoangGia_SL = 55; //Bien do SL tinh tu L1:
		string thamso = StringFormat("CheDoDanh=%d;VungTren=%f;VungDuoi=%f;BienDoGia_D1=%f;BienDoClose_1=%f;BienDoClose_2=%f;BienDoClose_3=%f;BienDoClose_D1=%f;"
			+ "Buy_SoLenhToiDa=%d;Buy_KhoiLuong=%s;Buy_BienDoGia=%s;Buy_BienDoTP=%s;Buy_DongLenh=%s;Buy_KhoangGia_SL=%f;"
			+ "Sell_SoLenhToiDa=%d;Sell_KhoiLuong=%s;Sell_BienDoGia=%s;Sell_BienDoTP=%s;Sell_DongLenh=%s;Sell_KhoangGia_SL=%f",
			CheDoDanh, VungTren, VungDuoi, BienDoGia_D1,
			BienDoClose_1, BienDoClose_2, BienDoClose_3, BienDoClose_D1,
			Buy_SoLenhToiDa, Buy_KhoiLuong, Buy_BienDoGia, Buy_BienDoTP, Buy_DongLenh, Buy_KhoangGia_SL,
			Sell_SoLenhToiDa, Sell_KhoiLuong, Sell_BienDoGia, Sell_BienDoTP, Sell_DongLenh, Sell_KhoangGia_SL
			 );

//luu y BienDoGia ko bao gom L1
*/
export function getThamSo_MT5_WOLF(state) {
  var Buy_khoiluong = "";
  state.Buy_KhoiLuong.map(
    (value, index) =>
      (Buy_khoiluong +=
        (Buy_khoiluong == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  var Sell_khoiluong = "";
  state.Sell_KhoiLuong.map(
    (value, index) =>
      (Sell_khoiluong +=
        (Sell_khoiluong == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );

  var Buy_biendoGia = "";
  state.Buy_BienDoGia.map((value, index) => {
    Buy_biendoGia +=
      (Buy_biendoGia == "" ? "" : "/") +
      "L" +
      (index + 1) +
      ":" +
      (value == null || value == "null" ? 0 : value);
  });
  var Sell_biendoGia = "";
  state.Sell_BienDoGia.map((value, index) => {
    Sell_biendoGia +=
      (Sell_biendoGia == "" ? "" : "/") +
      "L" +
      (index + 1) +
      ":" +
      (value == null || value == "null" ? 0 : value);
  });

  var Buy_biendoTP = "";
  state.Buy_BienDoTP.map(
    (value, index) =>
      (Buy_biendoTP +=
        (Buy_biendoTP == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  var Sell_biendoTP = "";
  state.Sell_BienDoTP.map(
    (value, index) =>
      (Sell_biendoTP +=
        (Sell_biendoTP == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );

  var Buy_dongLenh = "";
  state.Buy_DongLenh.map(
    (value, index) =>
      (Buy_dongLenh +=
        (Buy_dongLenh == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  var Sell_dongLenh = "";
  state.Sell_DongLenh.map(
    (value, index) =>
      (Sell_dongLenh +=
        (Sell_dongLenh == "" ? "" : "/") +
        "L" +
        (index + 1) +
        ":" +
        (value == null ? 0 : value))
  );
  return (
    "BotCode=" +
    state.BotCode +
    ";CheDoDanh=" +
    state.CheDoDanh +
    ";VungTren=" +
    state.VungTren +
    ";VungDuoi=" +
    state.VungDuoi +
    ";BienDoGia_D1=" +
    state.BienDoGia_D1 +
    ";BienDoClose_1=" +
    state.BienDoClose_1 +
    ";BienDoClose_2=" +
    state.BienDoClose_2 +
    ";BienDoClose_3=" +
    state.BienDoClose_3 +
    ";BienDoClose_D1=" +
    state.BienDoClose_D1 +
    ";Buy_SoLenhToiDa=" +
    state.Buy_SoLenhToiDa +
    ";Buy_KhoiLuong=" +
    Buy_khoiluong +
    ";Buy_BienDoGia=" +
    Buy_biendoGia +
    ";Buy_BienDoTP=" +
    Buy_biendoTP +
    ";Buy_DongLenh=" +
    Buy_dongLenh +
    ";Buy_KhoangGia_SL=" +
    state.Buy_KhoangGia_SL +
    ";Sell_SoLenhToiDa=" +
    state.Sell_SoLenhToiDa +
    ";Sell_KhoiLuong=" +
    Sell_khoiluong +
    ";Sell_BienDoGia=" +
    Sell_biendoGia +
    ";Sell_BienDoTP=" +
    Sell_biendoTP +
    ";Sell_DongLenh=" +
    Sell_dongLenh +
    ";Sell_KhoangGia_SL=" +
    state.Sell_KhoangGia_SL
  );
}
//lay danh sach so' tu string cac lenh L1, L2
function getThamSo_Array(str) {
  var ret = [];
  var list = str.split("/");
  list.map((item) => {
    ret.push(parseFloat(item.split(":")[1]));
  });
  while(ret.length < 18) {
    ret.push(0);
  }
  return ret;
}
///Chuyen tu tham so string thanh cau truc state
export function extractFrom_MT5_WOLF(thamso) {
  var state = {};
  var list = thamso.split(";");
  list.map((item) => {
    if (item != "") {
      var values = item.split("=");
      if (values[0] == "BotCode") {
        state.BotCode = values[1];
      } else if (values[0] == "CheDoDanh") {
        state.CheDoDanh = parseInt(values[1]);
      } else if (values[0] == "VungTren") {
        state.VungTren = parseFloat(values[1]);
      } else if (values[0] == "VungDuoi") {
        state.VungDuoi = parseFloat(values[1]);
      } else if (values[0] == "BienDoGia_D1") {
        state.BienDoGia_D1 = parseFloat(values[1]);
      } else if (values[0] == "BienDoClose_1") {
        state.BienDoClose_1 = parseFloat(values[1]);
      } else if (values[0] == "BienDoClose_2") {
        state.BienDoClose_2 = parseFloat(values[1]);
      } else if (values[0] == "BienDoClose_3") {
        state.BienDoClose_3 = parseFloat(values[1]);
      } else if (values[0] == "BienDoClose_D1") {
        state.BienDoClose_D1 = parseFloat(values[1]);
      } else if (values[0] == "Buy_SoLenhToiDa") {
        state.Buy_SoLenhToiDa = parseInt(values[1]);
      } else if (values[0] == "Buy_KhoangGia_SL") {
        state.Buy_KhoangGia_SL = parseFloat(values[1]);
      } else if (values[0] == "Buy_KhoiLuong") {
        state.Buy_KhoiLuong = getThamSo_Array(values[1]);
      } else if (values[0] == "Buy_BienDoGia") {
        state.Buy_BienDoGia = getThamSo_Array(values[1]); //do ko bao gom L1
      } else if (values[0] == "Buy_BienDoTP") {
        state.Buy_BienDoTP = getThamSo_Array(values[1]);
      } else if (values[0] == "Buy_DongLenh") {
        state.Buy_DongLenh = getThamSo_Array(values[1]);
      } else if (values[0] == "Sell_SoLenhToiDa") {
        state.Sell_SoLenhToiDa = parseInt(values[1]);
      } else if (values[0] == "Sell_KhoangGia_SL") {
        state.Sell_KhoangGia_SL = parseFloat(values[1]);
      } else if (values[0] == "Sell_KhoiLuong") {
        state.Sell_KhoiLuong = getThamSo_Array(values[1]);
      } else if (values[0] == "Sell_BienDoGia") {
        state.Sell_BienDoGia = getThamSo_Array(values[1]); //do ko bao gom L1
      } else if (values[0] == "Sell_BienDoTP") {
        state.Sell_BienDoTP = getThamSo_Array(values[1]);
      } else if (values[0] == "Sell_DongLenh") {
        state.Sell_DongLenh = getThamSo_Array(values[1]);
      }
    }
  });
  return state;
}
export function validate_ThamSo_WOLF(bot) {
  var config = bot.ConfigData;
  if (myLib.isEmpty(bot.BotCode) || myLib.isEmpty(bot.BotName)) {
    return false;
  }
  if (
    config.CheDoDanh == 1 &&
    (config.VungTren <= 0 || config.BienDoClose_1 <= 0)
  ) {
    return false;
  }
  if (
    config.CheDoDanh == 2 &&
    (config.VungTren <= 0 ||
      config.BienDoClose_2 <= 0 ||
      config.VungDuoi <= 0 ||
      config.VungDuoi >= config.VungTren)
  ) {
    return false;
  }
  if (
    config.CheDoDanh == 3 &&
    (config.VungDuoi <= 0 || config.BienDoClose_3 <= 0)
  ) {
    return false;
  }
  if (
    config.CheDoDanh == 4 &&
    (config.VungTren <= 0 ||
      config.BienDoClose_1 <= 0 ||
      config.VungDuoi <= 0 ||
      config.BienDoClose_3 <= 0)
  ) {
    return false;
  }
  if (
    config.CheDoDanh == 5 &&
    (config.BienDoGia_D1 <= 0 || config.BienDoClose_D1 <= 0)
  ) {
    return false;
  }

  //khoi luong
  var ret = true;
  config.Buy_KhoiLuong.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do gia
  config.Buy_BienDoGia.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do tp
  config.Buy_BienDoTP.map((item, index) => {
    if (index < config.Buy_SoLenhToiDa && item <= 0) {
      ret = false;
    }
  });
  //dong lenh
  config.Buy_DongLenh.map((item, index) => {
    if (index < config.Buy_SoLenhToiDa && (item < 0 || item > index + 1)) {
      ret = false;
    }
  });
  //serlllllllllllll
  //khoi luong

  config.Sell_KhoiLuong.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do gia
  config.Sell_BienDoGia.map((item, index) => {
    if (index < 18 && item <= 0) {
      ret = false;
    }
  });
  //bien do tp
  config.Sell_BienDoTP.map((item, index) => {
    if (index < config.Sell_SoLenhToiDa && item <= 0) {
      ret = false;
    }
  });
  //dong lenh
  config.Sell_DongLenh.map((item, index) => {
    if (index < config.Sell_SoLenhToiDa && (item < 0 || item > index + 1)) {
      ret = false;
    }
  });
  return ret;
}

export class BotEditControl_WOLF extends React.Component {
  constructor(props) {
    super(props);
    let values = props.BotData;
    this.state = {
      version: 0,
      BotCode: values.BotCode,
      BotName: values.BotName,
      BotMoTa: values.BotMoTa,
      CheDoDanh: 5,

      BienDoGia_D1: 5,
      VungTren: 0,
      VungDuoi: 0,

      BienDoClose_1: 0,
      BienDoClose_2: 0,
      BienDoClose_3: 0,
      BienDoClose_D1: 5,

      Buy_SoLenhToiDa: 16,
      Sell_SoLenhToiDa: 16,
      Buy_KhoangGia_SL: 55,
      Sell_KhoangGia_SL: 55,
      Active: true,
      HienThi: true,
      Buy_KhoiLuong: [
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
      ],
      Sell_KhoiLuong: [
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
        0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
      ],
      Buy_BienDoGia: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      Sell_BienDoGia: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      Buy_BienDoTP: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      Sell_BienDoTP: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      Buy_DongLenh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      Sell_DongLenh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ...extractFrom_MT5_WOLF(values.BotThamSo ?? ""),
    };
  }
  setMyState(state) {
    //bao cho thang cha biet
    this.state.version = state.version = this.state.version + 1; //tang version
    this.setState(state, () =>
      this.props.onChange ? this.props.onChange(this.state) : 0
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.BotData.version > prevState.version) {
      var values = nextProps.BotData;
      var ret = {
        version: values.version,
        Active: values.Active == undefined ? prevState.Active : values.Active,
        ...(values.ConfigData ?? extractFrom_MT5_WOLF(values.BotThamSo ?? "")),
        BotCode: values.BotCode,
        BotName: values.BotName,
        BotMoTa: values.BotMoTa,
      };
      return ret;
    }
    return null;
  }
  componentDidUpdate() {}
  render() {
    return (
      <Paper variant="outlined" sx={{}}>
        <Grid container spacing={1} columns={6} padding={1}>
          <Grid item xs={1}>
            <FormLabel>Mã/Tên:</FormLabel>
          </Grid>
          <Grid item xs={1}>
            <OutlinedInput
              fullWidth
              value={this.state.BotCode}
              inputProps={{
                maxLength: 6,
              }}
              onChange={(event) => {
                this.setMyState({
                  BotCode: myLib.toUpperCase(event.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.BotName}
              onChange={(event) =>
                this.setMyState({
                  BotName: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Mô tả:</FormLabel>
          </Grid>
          <Grid item xs={5}>
            <OutlinedInput
              fullWidth
              multiline
              value={this.state.BotMoTa}
              onChange={(event) =>
                this.setMyState({
                  BotMoTa: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <strong>Tham số:</strong>
          </Grid>
          <Grid item xs={1}>
            <FormLabel>Chế độ:</FormLabel>
          </Grid>
          <Grid item xs={2}>
            <CheDoMenu
              label={Menu_CheDo[parseInt(this.state.CheDoDanh) - 1].Label}
              SubActions={Menu_CheDo}
              onClick={(button) => this.setState({ CheDoDanh: button.ID })}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color={this.state.Active ? "primary" : "error"}
              onClick={(event) =>
                this.setMyState({
                  Active: this.state.Active ? false : true,
                })
              }
            >
              {this.state.Active ? "Đang Chạy" : "Đang ngừng"}
            </Button>
          </Grid>
          {this.state.CheDoDanh == 5 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Biên độ D1:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.BienDoGia_D1}
                  onValueChange={(value) =>
                    this.setMyState({
                      BienDoGia_D1: value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>Close:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.BienDoClose_D1}
                  onValueChange={(value) =>
                    this.setMyState({
                      BienDoClose_D1: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          {this.state.CheDoDanh != 3 && this.state.CheDoDanh != 5 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Vùng trên:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.VungTren}
                  onValueChange={(value) =>
                    this.setMyState({
                      VungTren: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          {this.state.CheDoDanh != 1 && this.state.CheDoDanh != 5 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Vùng dưới:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.VungDuoi}
                  onValueChange={(value) =>
                    this.setMyState({
                      VungDuoi: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          {this.state.CheDoDanh == 1 || this.state.CheDoDanh == 4 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Close V1:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.BienDoClose_1}
                  onValueChange={(value) =>
                    this.setMyState({
                      BienDoClose_1: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          {this.state.CheDoDanh == 2 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Close V2:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.BienDoClose_2}
                  onValueChange={(value) =>
                    this.setMyState({
                      BienDoClose_2: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          {this.state.CheDoDanh == 3 || this.state.CheDoDanh == 4 ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Close V3:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.BienDoClose_3}
                  onValueChange={(value) =>
                    this.setMyState({
                      BienDoClose_3: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={6}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={this.state.HienThi ? "outlined" : "text"}
              onClick={() => this.setState({ HienThi: true })}
            >
              Thông số BUY
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={!this.state.HienThi ? "outlined" : "text"}
              onClick={() => this.setState({ HienThi: false })}
            >
              Thông số SELL
            </Button>
          </Grid>
          <Grid item xs={1}></Grid>
          {this.state.HienThi ? (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Số lệnh:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={0}
                  color="blue"
                  value={this.state.Buy_SoLenhToiDa}
                  onValueChange={(value) => {
                    this.setMyState({
                      Buy_SoLenhToiDa: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>Cắt lỗ:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.Buy_KhoangGia_SL}
                  onValueChange={(value) =>
                    this.setMyState({
                      Buy_KhoangGia_SL: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={1}>
                <FormLabel>Số lệnh:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={0}
                  color="blue"
                  value={this.state.Sell_SoLenhToiDa}
                  onValueChange={(value) => {
                    this.setMyState({
                      Sell_SoLenhToiDa: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>Cắt lỗ:</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={2}
                  color="blue"
                  value={this.state.Sell_KhoangGia_SL}
                  onValueChange={(value) =>
                    this.setMyState({
                      Sell_KhoangGia_SL: value,
                    })
                  }
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={2}></Grid>
          <Grid item xs={6}>
            <hr />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <strong>Khối lượng</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Biên độ giá</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Biên độ TP</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Đóng lệnh</strong>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            {this.state.HienThi
              ? this.state.Buy_KhoiLuong.map((value, index) => (
                  <OutlinedInput
                    inputProps={{ style: { textAlign: "right" } }}
                    readOnly
                    value={"L" + (index + 1) + " => "}
                  ></OutlinedInput>
                ))
              : this.state.Sell_KhoiLuong.map((value, index) => (
                  <OutlinedInput
                    inputProps={{ style: { textAlign: "right" } }}
                    readOnly
                    value={"L" + (index + 1) + " => "}
                  ></OutlinedInput>
                ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.HienThi
              ? this.state.Buy_KhoiLuong.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    value={this.state.Buy_KhoiLuong[index]}
                    onValueChange={(value) => {
                      this.state.Buy_KhoiLuong[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))
              : this.state.Sell_KhoiLuong.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    value={this.state.Sell_KhoiLuong[index]}
                    onValueChange={(value) => {
                      this.state.Sell_KhoiLuong[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.HienThi
              ? this.state.Buy_BienDoGia.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    disabled={index == 0}
                    value={this.state.Buy_BienDoGia[index]}
                    onValueChange={(value) => {
                      this.state.Buy_BienDoGia[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))
              : this.state.Sell_BienDoGia.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    disabled={index == 0}
                    value={this.state.Sell_BienDoGia[index]}
                    onValueChange={(value) => {
                      this.state.Sell_BienDoGia[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.HienThi
              ? this.state.Buy_BienDoTP.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    value={this.state.Buy_BienDoTP[index]}
                    onValueChange={(value) => {
                      this.state.Buy_BienDoTP[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))
              : this.state.Sell_BienDoTP.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={2}
                    color="blue"
                    value={this.state.Sell_BienDoTP[index]}
                    onValueChange={(value) => {
                      this.state.Sell_BienDoTP[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))}
          </Grid>
          <Grid item xs={1}>
            {this.state.HienThi
              ? this.state.Buy_DongLenh.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={0}
                    color="blue"
                    value={this.state.Buy_DongLenh[index]}
                    onValueChange={(value) => {
                      this.state.Buy_DongLenh[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))
              : this.state.Sell_DongLenh.map((value, index) => (
                  <MyNumberEditor
                    decimalScale={0}
                    color="blue"
                    value={this.state.Sell_DongLenh[index]}
                    onValueChange={(value) => {
                      this.state.Sell_DongLenh[index] = value;
                      this.setMyState({
                        changed: new Date(),
                      });
                    }}
                  />
                ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles({}, { withTheme: true })(BotEditControl_WOLF);

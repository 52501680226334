import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Link,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
import MetaCloudAccountInfo from "./MetaCloudAccountInfo";
import PlaceOrderDialog from "./PlaceOrderDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

export default function MetaCloudAccountEditor(props) {
  const [saving, setSaving] = React.useState(false);
  const [DM_TaiKhoan, setDM_TaiKhoan] = React.useState([]);
  const [showAccountInfo, setShowAccountInfo] = React.useState(false);
  const [accountInfo, setAccountInfo] = React.useState({});
  const [callLoadData, setCallLoadData] = React.useState(false); //bien trang thai de thuc hien call loadData

  const [showPlaceOrder, setShowPlaceOrder] = React.useState(false);
  //thuc hien load du lieu
  function loadData() {
    //load du lieu token hien thoi
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_TaiKhoan_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        setSaving(false);
        setDM_TaiKhoan(response.DM_TaiKhoan);
      })
      .catch((error) => {
        setSaving(false);
        showError(error);
      });
  }
  //thuc hien ghi nho du lieu
  function doSave() {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_TaiKhoan_SyncCloud",
        ThamSo: {},
      })
      .then((response) => {
        setSaving(false);
        setCallLoadData(true); //phat lenh yeu cau load data qua effect
      })
      .catch((error) => {
        setSaving(false);
        showError(error);
      });
  }
  //on load
  React.useEffect(() => {
    loadData();
  }, [callLoadData]);
  return (
    <>
      {showAccountInfo ? (
        <MetaCloudAccountInfo
          accountInfo={accountInfo}
          onBackClicked={() => setShowAccountInfo(false)}
        />
      ) : (
        <>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 60,
            }}
          >
            <Typography variant="h6">
              Danh sách tài khoản trên MetaCloud:
            </Typography>
          </Paper>
          <Divider />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth1: 150 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tên</TableCell>
                  <TableCell align="right">Vùng</TableCell>
                  <TableCell align="right">Trạng thái</TableCell>
                  <TableCell align="right">Cập nhật</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DM_TaiKhoan.map((row, index) => (
                  <TableRow
                    key={row.AccountId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Button
                        onClick={() => {
                          setShowAccountInfo(true);
                          setAccountInfo(JSON.parse(row.MetaInfo));
                          return false;
                        }}
                      >
                        {row.AccountName}
                      </Button>
                    </TableCell>
                    <TableCell align="right">{row.Region}</TableCell>
                    <TableCell align="right">
                      {row.CloudState === 1 ? "Hoạt động" : "???"}
                    </TableCell>
                    <TableCell align="right">
                      {moment(row.NgayCN).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider />
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 60,
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={saving}
                  onClick={() => doSave()}
                >
                  Đồng bộ
                </Button>
              </Grid>
              <Grid item xs={12}>
                 
              </Grid>
              <Grid item xs={12}>
                {saving ? <CircularProgress size={24} /> : null}
              </Grid>
            </Grid>
          </Paper>
          {showPlaceOrder ? (
            <PlaceOrderDialog
              open={true}
              bidPrice={2023.22}
              askPrice={2023.01}
              close={() => setShowPlaceOrder(false)}
            />
          ) : null}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </>
  );
}

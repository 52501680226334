'use strict';

import fs from 'fs';

import MetaApiClient from '../metaApi.client';
import FormData from 'form-data';

/**
 * metaapi.cloud provisioning profile API client (see https://metaapi.cloud/docs/provisioning/)
 */
export default class ProvisioningProfileClient extends MetaApiClient {

  /**
   * Provisioning profile model
   * @typedef {Object} ProvisioningProfileDto
   * @property {String} _id provisioning profile unique identifier
   * @property {String} name provisioning profile name
   * @property {Number} version MetaTrader version (allowed values are 4 and 5)
   * @property {String} status provisioning profile status (allowed values are new and active)
   * @property {String} brokerTimezone broker timezone name from Time Zone Database
   * @property {String} brokerDSTSwitchTimezone broker DST switch timezone name from Time Zone Database
   */

  /**
   * MT version
   * @typedef {4 | 5} Version
   */

  /**
   * Provisioning profile type
   * @typedef {'mtTerminal' | 'managerApi'} ProvisioningProfileType
   */

  /**
   * Provisioning profile status
   * @typedef {'new' | 'active'} ProvisioningProfileStatus
   */

  /**
   * Get provisioning profiles API version
   * @typedef {'1' | '2'} GetProvisioningProfilesApiVersion
   */

  /**
   * @typedef {Object} ProvisioningProfilesFilter
   * @property {number} [offset] Search offset (defaults to 0) (must be greater or equal to 0)
   * @property {number} [limit] Search limit (defaults to 1000) 
   * (must be greater or equal to 1 and less or equal to 1000)
   * @property {Version} [version] MT version
   * @property {ProvisioningProfileType} [type] Profile type
   * @property {ProvisioningProfileStatus} [status] Profile status
   * @property {string} [query] Partially search over provisioning profile name to match query
   */

  /**
   * Provisioning profiles list model
   * @typedef {Object} ProvisioningProfilesListDto
   * @property {String} count provisioning profiles count
   * @property {Array<ProvisioningProfileDto>} items provisioning profiles list
   */


  /**
   * Retrieves provisioning profiles owned by user
   * (see https://metaapi.cloud/docs/provisioning/api/provisioningProfile/readProvisioningProfiles/)
   * Method is accessible only with API access token
   * @param {ProvisioningProfilesFilter} [profilesFilter] provisioning profiles filter
   * @param {GetProvisioningProfilesApiVersion} [apiVersion] api version to use
   * @return {Promise<Array<ProvisioningProfileDto> | ProvisioningProfilesListDto>} promise resolving with provisioning profiles found
   */
  getProvisioningProfiles(profilesFilter = {}, apiVersion) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('getProvisioningProfiles');
    }
    const opts = {
      url: `${this._host}/users/current/provisioning-profiles`,
      method: 'GET',
      params: profilesFilter,
      headers: {
        'auth-token': this._token
      },
      json: true
    };
    if (apiVersion) {
      opts.headers['api-version'] = apiVersion;
    }
    return this._httpClient.request(opts, 'getProvisioningProfiles');
  }

  /**
   * Retrieves a provisioning profile by id (see
   * https://metaapi.cloud/docs/provisioning/api/provisioningProfile/readProvisioningProfile/). Throws an error if
   * profile is not found.
   * Method is accessible only with API access token
   * @param {String} id provisioning profile id
   * @return {Promise<ProvisioningProfileDto>} promise resolving with provisioning profile found
   */
  getProvisioningProfile(id) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('getProvisioningProfile');
    }
    const opts = {
      url: `${this._host}/users/current/provisioning-profiles/${id}`,
      method: 'GET',
      headers: {
        'auth-token': this._token
      },
      json: true
    };
    return this._httpClient.request(opts, 'getProvisioningProfile');
  }

  /**
   * New provisioning profile model
   * @typedef {Object} NewProvisioningProfileDto
   * @property {String} name provisioning profile name
   * @property {Number} version MetaTrader version (allowed values are 4 and 5)
   * @property {String} brokerTimezone broker timezone name from Time Zone Database
   * @property {String} brokerDSTSwitchTimezone broker DST switch timezone name from Time Zone Database
   */

  /**
   * Provisioning profile id model
   * @typedef {Object} ProvisioningProfileIdDto
   * @property {String} id provisioning profile unique identifier
   */

  /**
   * Creates a new provisioning profile (see
   * https://metaapi.cloud/docs/provisioning/api/provisioningProfile/createNewProvisioningProfile/). After creating a
   * provisioning profile you are required to upload extra files in order to activate the profile for further use.
   * Method is accessible only with API access token
   * @param {NewProvisioningProfileDto} provisioningProfile provisioning profile to create
   * @return {Promise<ProvisioningProfileIdDto>} promise resolving with an id of the provisioning profile created
   */
  createProvisioningProfile(provisioningProfile) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('createProvisioningProfile');
    }
    const opts = {
      url: `${this._host}/users/current/provisioning-profiles`,
      method: 'POST',
      headers: {
        'auth-token': this._token
      },
      json: true,
      data: provisioningProfile
    };
    return this._httpClient.request(opts, 'createProvisioningProfile');
  }

  /**
   * Uploads a file to a provisioning profile (see
   * https://metaapi.cloud/docs/provisioning/api/provisioningProfile/uploadFilesToProvisioningProfile/). Uploading a
   * file by name is allowed only for Node.js.
   * Method is accessible only with API access token
   * @param {String} provisioningProfileId provisioning profile id to upload file to
   * @param {String} fileName name of the file to upload. Allowed values are servers.dat for MT5 profile, broker.srv for
   * MT4 profile
   * @param {String|Buffer} file path to a file to upload or buffer containing file contents
   * @return {Promise} promise resolving when file upload is completed
   */
  uploadProvisioningProfileFile(provisioningProfileId, fileName, file) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('uploadProvisioningProfileFile');
    }

    const formData = new FormData();
    if (typeof file === 'string') {
      file = fs.createReadStream(file);
      formData.append('file', file);
    } else {
      formData.append('file', file, 'serverFile');
    }

    const opts = {
      method: 'PUT',
      url: `${this._host}/users/current/provisioning-profiles/${provisioningProfileId}/${fileName}`,
      data: formData,
      headers: {
        ...formData.getHeaders(),
        'auth-token': this._token,
      }
    };
    return this._httpClient.request(opts, 'uploadProvisioningProfileFile');
  }

  /**
   * Deletes a provisioning profile (see
   * https://metaapi.cloud/docs/provisioning/api/provisioningProfile/deleteProvisioningProfile/). Please note that in
   * order to delete a provisioning profile you need to delete MT accounts connected to it first.
   * Method is accessible only with API access token
   * @param {String} id provisioning profile id
   * @return {Promise} promise resolving when provisioning profile is deleted
   */
  deleteProvisioningProfile(id) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('deleteProvisioningProfile');
    }
    const opts = {
      url: `${this._host}/users/current/provisioning-profiles/${id}`,
      method: 'DELETE',
      headers: {
        'auth-token': this._token
      },
      json: true
    };
    return this._httpClient.request(opts, 'deleteProvisioningProfile');
  }

  /**
   * Updated provisioning profile data
   * @typedef {Object} ProvisioningProfileUpdateDto
   * @property {String} name provisioning profile name
   */

  /**
   * Updates existing provisioning profile data (see
   * https://metaapi.cloud/docs/provisioning/api/provisioningProfile/updateProvisioningProfile/).
   * Method is accessible only with API access token
   * @param {String} id provisioning profile id
   * @param {ProvisioningProfileUpdateDto} provisioningProfile updated provisioning profile
   * @return {Promise} promise resolving when provisioning profile is updated
   */
  updateProvisioningProfile(id, provisioningProfile) {
    if (this._isNotJwtToken()) {
      return this._handleNoAccessError('updateProvisioningProfile');
    }
    const opts = {
      url: `${this._host}/users/current/provisioning-profiles/${id}`,
      method: 'PUT',
      headers: {
        'auth-token': this._token
      },
      json: true,
      data: provisioningProfile
    };
    return this._httpClient.request(opts, 'updateProvisioningProfile');
  }

}

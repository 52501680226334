import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MainListItems, SecondaryListItems } from "./listItems";
import { ToastContainer, toast } from "react-toastify";
import MyActionMenu from "../components/MyActionMenu";
import LogoutIcon from "@mui/icons-material/Logout";

/*Cac components */
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import LoginControl from "../controls/LoginControl";
import TradingControl from "../controls/TradingControl";
import MetaCloudTokenEditor from "../controls/MetaCloudTokenEditor";
import MetaCloudAccountEditor from "../controls/MetaCloudAccountEditor";
import MarketWatchControl from "../controls/MarketWatchControl";
import { OutlinedInput } from "@mui/material";
import { HistoryControl } from "../controls/HistoryControl";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://incomp.vn/">
        Incomp Holding
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(10),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}));

function getUserMenuList() {
  return [
    {
      ID: "UserName",
      Label: global.User ? global.User.UserName : "None",
      Icon: <AccountCircleIcon />,
    },
    {
      ID: "Divider1",
      Label: "-",
    },
    {
      ID: "logout",
      Label: "Log out",
      Icon: <LogoutIcon />,
    },
  ];
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function HomePage() {
  const [userName, setUserName] = React.useState("");
  const [showLogin, setShowLogin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [activePage, setActivePage] = React.useState("Accounts");
  const toggleDrawer = () => {
    setOpen(!open);
  };
  window.onUnAuthorize = () => {
    if (!showLogin) {
      setShowLogin(true);
    }
  };
  function doCheckUser() {
    server
      .query("User/GetUser", {})
      .then((response) => {
        global.User = response;
        setUserName(response.UserName);
        setActivePage("Trading");
      })
      .catch((error) => {
        console.log(error);
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          var code = obj.code;
          console.log(obj);
          if (code === 401) {
            //request login
            setShowLogin(true); // hien thi form login
          } else {
            log = obj.traceLog;
            var log_detail = JSON.parse(log);
            showError(error);
          }
        } catch (e) {
          message = error + "";
          //showError(error);
        }
      });
  }
  React.useEffect(() => {
    //kiem tra login hay chua
    doCheckUser();
  }, []);
  console.log(activePage);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Place Of Self Positioning
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <MyActionMenu
              buttonIcon={<AccountCircleIcon />}
              SubActions={getUserMenuList()}
              onClick={(button) => {
                if (button.ID == "logout") {
                  server.saveToken("", true);
                  setShowLogin(true);
                }
              }}
            ></MyActionMenu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <DrawerHeader>
              {open ? (
                <>
                  <a href="#" onClick={toggleDrawer}>
                    <img
                      src="../incomp.png"
                      alt="Incomp Holding"
                      style={{
                        width: drawerWidth,
                        height: 65,
                      }}
                    ></img>
                  </a>
                </>
              ) : null}
            </DrawerHeader>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems
              activePage={activePage}
              onClick={(page) => setActivePage(page)}
            />
            <Divider sx={{ my: 1 }} />
            <SecondaryListItems
              activePage={activePage}
              showGroup={open}
              onClick={(page) => setActivePage(page)}
            />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box>
            {showLogin ? (
              <LoginControl
                close={(done) => {
                  if (done) {
                    setActivePage("Trading");
                    setShowLogin(false);
                  }
                }}
              />
            ) : activePage == "Trading" ? (
              <MarketWatchControl />
            ) : 
            activePage == "Orders" ? (
              <HistoryControl 

                
              />

            ):
            
            
            null }

            <Copyright sx={{ pt: 4 }} />
          </Box>
        </Box>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </React.Fragment>
  );
}

'use strict';

import * as helpers from './helpers';

/**
 * Promise that can be used as a handle. It will not raise errors when rejected until it is explicitly awaited or
 * catch is set
 * @template T Promise result type
 * @implements {Promise}
 */
export class HandlePromise {

  /**
   * Constructs instance
   * @param {Promise<T>} [promise] original promise to wrap into the handle
   */
  constructor(promise) {
    this._promise = helpers.createHandlePromise();
    this.then = this._promise.then.bind(this._promise);
    this.catch = this._promise.catch.bind(this._promise);
    this.finally = this._promise.finally.bind(this._promise);
    this.resolve = this._promise.resolve.bind(this._promise);
    this.reject = this._promise.reject.bind(this._promise);
    this.timeout = this._promise.timeout.bind(this._promise);
    if (promise) {
      promise.then(this.resolve).catch(() => {});
      promise.catch(this.reject);
    }
  }

  /**
   * Returns whether the promise has resolved
   * @returns {boolean} whether the promise has resolved
   */
  get resolved() {
    return !!this._promise.resolved;
  }

  /**
   * Returns whether the promise has rejected
   * @returns {boolean} whether the promise has rejected
   */
  get rejected() {
    return !!this._promise.rejected;
  }

  /**
   * Returns whether the promise has completed (either resolved or rejected)
   * @returns {boolean} whether the promise has completed (either resolved or rejected)
   */
  get completed() {
    return !!this._promise.completed;
  }
}

/**
 * Represents a handle promise, resolving when needed condition is reached
 */
export class ConditionPromise extends HandlePromise {

  /**
   * Constructs instance
   * @param {() => boolean} predicate function that returns true when the condition is reached
   */
  constructor(predicate) {
    super();
    this._predicate = predicate;
    this.check();
  }

  /**
   * Calls the predicate to check if the promise should be resolved
   */
  check() {
    if (this._predicate()) {
      this.resolve();
    }
  }
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import { experimental_useEffectEvent as useEffectEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
  showWarning,
  showDone,
} from "../components/common";
import moment from "moment";

import MetaApi, {
  StreamingMetaApiConnectionInstance,
  SynchronizationListener,
} from "metaapi.cloud-sdk";
import OpeningOrderControl from "./OpeningOrderControl";
import AccountStateControl from "./AccountStateControl";
import PendingOrderControl from "./PendingOrderControl";
import SymbolSelectDialog from "./SymbolSelectDialog";
import PlaceOrderDialog from "./PlaceOrderDialog";
import SimpleDialog from "../components/SimpleDialog";
import WatchListDialog from "./WatchListDialog";
import {
  doPlaceOrder,
  doCancelOrder,
  doRegisterWatch,
  modifyPosition,
  closePosition,
  modifyPendingOrder,
  closePositionPartial,
  doCloseAll,
} from "../components/trading";
import { act } from "react-dom/test-utils";
import OrderModifyDialog from "./OrderModifyDialog";
import PartialCloseDialog from "./PartialCloseDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

//sap xep position theo nhom' symbol, buy/sell
function sortOpenPosition(list) {
  var newList = list.sort((a, b) => {
    return a.symbol + a.type > b.symbol + b.type ? 1 : -1;
  });
  return newList;
}

function AccountTradingControlPure(props) {
  const [showSymbolSelect, setShowSymbolSelect] = React.useState(false); //hien thi form
  const [showPlaceOrder, setShowPlaceOrder] = React.useState(false); //hien thi form edit order
  const [edittingOrder, setEdittingOrder] = React.useState(null); //hien thi form modify order
  const [confirmCloseOrder, setConfirmCloseOrder] = React.useState(false);
  const [positionToClose, setPositionToClose] = React.useState(null); //position muon close
  const [positionToClosePartial, setPositionToClosePartial] = React.useState(null); //position muon close 1 phan

  const [sizeWindow, setSizeWindow] = React.useState([0, 0]); // theo doi kich thuoc win dow
  const [test, setTest] = React.useState(0);
  const [brokerConnected, setBrokerConnected] = React.useState(false); //trang thai ket noi den broker
  const [terminalState, setTerminalState] = React.useState(null);
  const [currentConnection, setCurrentConnection] = React.useState(null);
  const [account, setAccount] = React.useState(props.Account); //taia khoan giao dich

  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [symbolPrices, setSymbolPrices] = React.useState({}); //chua thong tin gia'
  const [newPrice, setNewPrice] = React.useState(null);
  const [okRunning, setOKRunning] = React.useState(false);
  let metaAccount = terminalState ? terminalState.accountInformation : {};

  let api = props.MTClientApi; //dia chi api, trich xuat domain trong api nay
  let domain = api.substring(api.split(".")[0].length + 1);
  const [state, setState] = React.useState({
    metaCloudAccountId: props.metaCloudAccountId, //ID cua tai khoan
    metaCloudRealtimeToken: props.metaCloudRealtimeToken, //token realtime
    metaCloudDomain: domain, //ten mien se ket noi den
  });

  //trang thai ket noi
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  //danh sach positions cua tai khoan
  let openPositons = terminalState ? terminalState.positions : []; //lenh da mo
  let pendingOrders = terminalState ? terminalState.orders : []; //danh sach lenh limit
  //khai bao listener

  function metaListenerFunc(initPositions) {
    this.lastInstanceIndex = "";
    this.brokerConnected = false;
    this.terminalState = {};
    this.test = function () {};
    ////////////////////////CAC HAM XU LY SU KIEN TU METAAPI/////////////////////////////
    this.onConnected = function (instanceIndex, replicas) {
      console.log("connected..........");
    };
    this.onHealthStatus = function (instanceIndex, status) {};
    this.onDisconnected = function (instanceIndex) {
      console.log("dis connected with terminal..........");
    };
    this.onBrokerConnectionStatusChanged = function (instanceIndex, connected) {
      console.log("ket noi den broker change, connected =", connected);
      if (this.brokerConnected != connected) {
        this.brokerConnected = connected;
        setBrokerConnected(connected);
      }
    };
    this.onSynchronizationStarted = function (
      instanceIndex,
      specificationsHash,
      positionsHash,
      ordersHash,
      synchronizationId
    ) {
      console.log("bat dau thuc hien dong bo...");
    };
    this.onAccountInformationUpdated = function (
      instanceIndex,
      accountInformation
    ) {
      console.log("thong tin tai khoan da thay doi ", accountInformation);
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPositionsReplaced = function (instanceIndex, positions) {
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPositionsSynchronized = function (instanceIndex, synchronizationId) {
      //console.log("positions da dong bo xong");
    };
    this.onPositionsUpdated = function (
      instanceIndex,
      positions,
      removedPositionIds
    ) {};
    this.onPositionUpdated = function (instanceIndex, position) {
      //khi 1 position updated
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        showSuccess(
          props.Account.AccountName +
            " # " +
            position.symbol +
            " # " +
            position.id +
            " updated"
        );
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPositionRemoved = function (instanceIndex, positionId) {
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        showSuccess(
          props.Account.AccountName + " # " + positionId + " removed"
        );
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPendingOrdersReplaced = function (instanceIndex, orders) {
      console.log(orders);
    };
    this.onPendingOrdersUpdated = function (
      instanceIndex,
      orders,
      completedOrderIds
    ) {};
    this.onPendingOrderUpdated = function (instanceIndex, order) {
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        showSuccess(
          props.Account.AccountName + " # " + order.positionId + " updated"
        );
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPendingOrderCompleted = function (instanceIndex, orderId) {
      if (
        instanceIndex == this.lastInstanceIndex ||
        this.lastInstanceIndex == ""
      ) {
        showSuccess(
          props.Account.AccountName + " # " + orderId + " open/removed"
        );
        this.lastInstanceIndex = instanceIndex;
      }
    };
    this.onPendingOrdersSynchronized = function (
      instanceIndex,
      synchronizationId
    ) {};
    this.onHistoryOrderAdded = function (instanceIndex, historyOrder) {};
    this.onHistoryOrdersSynchronized = function (
      instanceIndex,
      synchronizationId
    ) {};
    this.onDealAdded = function (instanceIndex, deal) {};
    this.onDealsSynchronized = function (instanceIndex, synchronizationId) {};
    this.onSymbolSpecificationUpdated = function (
      instanceIndex,
      specification
    ) {};
    this.onSymbolSpecificationRemoved = function (instanceIndex, symbol) {};
    this.onSymbolSpecificationsUpdated = function (
      instanceIndex,
      specifications,
      removedSymbols
    ) {};
    this.onSymbolPriceUpdated = function (instanceIndex, price) {
      setNewPrice(price);
    };
    this.onSymbolPricesUpdated = function (
      instanceIndex,
      prices,
      equity,
      margin,
      freeMargin,
      marginLevel,
      accountCurrencyExchangeRate
    ) {
      //console.log(prices);
      //setTest(Math.random());
      //console.log(prices);
    };
    this.onCandlesUpdated = function (
      instanceIndex,
      candles,
      equity,
      margin,
      freeMargin,
      marginLevel,
      accountCurrencyExchangeRate
    ) {};
    this.onTicksUpdated = function (
      instanceIndex,
      ticks,
      equity,
      margin,
      freeMargin,
      marginLevel,
      accountCurrencyExchangeRate
    ) {};
    this.onBooksUpdated = function (
      instanceIndex,
      books,
      equity,
      margin,
      freeMargin,
      marginLevel,
      accountCurrencyExchangeRate
    ) {};
    this.onSubscriptionDowngraded = function (
      instanceIndex,
      symbol,
      updates,
      unsubscriptions
    ) {};
    this.onStreamClosed = function (instanceIndex) {
      this.lastInstanceIndex = "";
    };
    this.onUnsubscribeRegion = function (region) {};
  }
  const [metaListener, setMetaListener] = React.useState(
    new metaListenerFunc()
  );
  //viet cac effect de cap nhat trang thai cho metaListner
  React.useEffect(() => {
    metaListener.myOpenPositions = openPositons; //gan lai gia tri
  }, [openPositons]);
  //ham thuc hien ket noi den meta api streaming
  async function connectToMetaApi(meta) {
    // Get instance of MetaApi with your MetaApi token
    const metaApi = new MetaApi(state.metaCloudRealtimeToken, {
      domain1: state.metaCloudDomain,
    });
    // Get MetaTrader account
    const account = await metaApi.metatraderAccountApi.getAccount(
      state.metaCloudAccountId
    );
    // Get connection instance
    await account.waitConnected();
    const connection = account.getStreamingConnection();
    // Wait until connection is established
    await connection.connect();
    connection.addSynchronizationListener(meta);
    await connection.waitSynchronized();
    setCurrentConnection(connection);
    return connection;
  }
  //kien tao ket noi
  async function makeConnection(meta) {
    console.log("making connection...");
    setIsConnecting(true);
    const connection = await connectToMetaApi(meta);
    const terminalState = connection.terminalState;
    console.log(terminalState);
    meta.terminalState = terminalState;
    setTerminalState(terminalState);
    let acc = terminalState.accountInformation;
    return connection;
  }
  //effect xu ly ket noi
  React.useEffect(() => {
    if (isConnected || isConnecting) {
      return;
    }
    var socket = null;
    makeConnection(metaListener)
      .then((connection) => {
        socket = connection;
        setIsConnected(true);
        setCurrentConnection(connection);
        console.log("connection done......", socket);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsConnecting(false));
  }, [isConnecting]);
  //chua tim ra cach nen phai dung useEffect de close connection
  React.useEffect(() => {
    return () => {
      if (currentConnection) {
        console.log("close conection...");
        currentConnection.removeSynchronizationListener(metaListener);
        currentConnection.close();
      }
    };
  }, [currentConnection]);
  React.useEffect(() => {
    if (newPrice) {
      var list = { ...symbolPrices };
      list[newPrice.symbol] = newPrice;
      setSymbolPrices(list);
    }
  }, [newPrice]);
  //hook de giam sat su thay doi winow size
  React.useLayoutEffect(() => {
    function updateSize() {
      setSizeWindow([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  //thuc hien yeu cau ket noi'
  function doConnect() {
    if (isConnected) {
      return;
    }
    setIsConnecting(true);
  }
  //reset
  function resetConnection() {
    setIsConnected(false);
    setIsConnecting(false);
  }
  //xu ly action doi voi opening position
  function doActionOpeningOrder(action, order) {
    console.log(order);
    if (action === "modify") {
      //modify takeprofit position
      setEdittingOrder(order);
    }
    if (action === "close") {
      setPositionToClose(order);
    }
    if(action === "partial") {
      setPositionToClosePartial(order);
    }
  }
  function doActionPendingOrder(action, order) {
    if(action === "cancel") {
      setSelectedOrder(order);
      setConfirmCloseOrder(true);
    }
    if(action === "modify") {
      setEdittingOrder(order);
    }

  }
  //thong tin ve price cua cac symbold dc update lien tuc
  let pricesBySymbol = symbolPrices;
  //console.log(terminalState ? terminalState._combinedState : null);
  let symbolSpecifications = terminalState ? terminalState.specifications : [];
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container columns={12} spacing={1}>
          <Grid item xs={12}>
            <AccountStateControl
              BrokerConnected={brokerConnected}
              Account={account}
              AccountEquity={metaAccount.equity}
              isConnected={isConnected}
              isConnecting={isConnecting}
              metaAccount={metaAccount}
              onAction={(button) => {
                if (button.ID == "Symbols") {
                  setShowSymbolSelect(true);
                }
                if (button.ID == "PlaceOrder") {
                  setShowPlaceOrder(true);
                }
              }}
            />
          </Grid>
          {openPositons.map((metaPosition, index) => (
            <Grid item xs={6} key={"P" + index}>
              <OpeningOrderControl
                metaPosition={metaPosition}
                doAction={(action) =>
                  doActionOpeningOrder(action, metaPosition)
                }
              />
            </Grid>
          ))}
          {pendingOrders.length > 0 ? (
            <Grid item xs={12}>
              <hr />
            </Grid>
          ) : null}
          {pendingOrders.map((order, index) => (
            <Grid item xs={6} key={"O" + index}>
              <PendingOrderControl
                metaPosition={order}
                doAction={(action) =>
                  doActionPendingOrder(action, order)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Divider />
      {showSymbolSelect ? (
        <WatchListDialog
          open={true}
          pricesBySymbol={pricesBySymbol}
          BrokerSymbols={terminalState.specifications}
          doWatch={(symbolItem, add, whenDone) => {
            doRegisterWatch(currentConnection, symbolItem, add, () => {
              whenDone();
              //xoa khoi danh sach
              if (!add) {
                var newPrices = { ...symbolPrices };
                delete newPrices[symbolItem.symbol];
                setSymbolPrices(newPrices);
              }
            });
          }}
          close={() => setShowSymbolSelect(false)}
        />
      ) : null}
      {showPlaceOrder ? (
        <PlaceOrderDialog
          open={true}
          pricesBySymbol={pricesBySymbol}
          symbolSpecifications={symbolSpecifications}
          close={() => setShowPlaceOrder(false)}
          doSave={(order, whenDone) =>
            doPlaceOrder(currentConnection, order, whenDone)
          }
          doCloseAll = {()=>{
            doCloseAll(currentConnection);
          }}
        />
      ) : null}
      {edittingOrder === null ? null : (
        <OrderModifyDialog
          open={true}
          edittingOrder={edittingOrder}
          pricesBySymbol={pricesBySymbol}
          symbolSpecifications={symbolSpecifications}
          close={() => setEdittingOrder(null)}
          doSave={(order, whenDone) => {
            if (
              edittingOrder.type == "POSITION_TYPE_BUY" ||
              edittingOrder.type == "POSITION_TYPE_SELL"
            ) {
              modifyPosition(
                currentConnection,
                edittingOrder.id,
                order.stopLoss,
                order.takeProfit,
                whenDone
              );
            }
            if(edittingOrder.type == "ORDER_TYPE_BUY_LIMIT" ||
            edittingOrder.type == "ORDER_TYPE_SELL_LIMIT") {
              modifyPendingOrder(currentConnection, edittingOrder.id, order.price, order.stopLoss, order.takeProfit, whenDone );
            }
          }}
        />
      )}
      {positionToClosePartial === null ? null : (
        <PartialCloseDialog
          open={true}
          edittingOrder={positionToClosePartial}
          pricesBySymbol={pricesBySymbol}
          symbolSpecifications={symbolSpecifications}
          close={() => setPositionToClosePartial(null)}
          doSave={(order, whenDone) => {
            if (
              positionToClosePartial.type == "POSITION_TYPE_BUY" ||
              positionToClosePartial.type == "POSITION_TYPE_SELL"
            ) {
              closePositionPartial(
                currentConnection,
                positionToClosePartial.id,
                order.volume,
                whenDone
              );
            }
             
          }}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={true}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
      {confirmCloseOrder ? (
        <SimpleDialog
          confirm
          message={
            "Cancel limit order:" +
            selectedOrder.symbol +
            "#" +
            selectedOrder.id +
            " ?"
          }
          open={true}
          OKRunning={okRunning}
          close={(ok) => {
            if (ok) {
              //thuc hien close
              if (!okRunning) {
                setOKRunning(true);
                doCancelOrder(currentConnection, selectedOrder, (ok) => {
                  setConfirmCloseOrder(false);
                  setOKRunning(false);
                });
              }
            } else {
              setConfirmCloseOrder(false);
            }
          }}
        />
      ) : null}
      {positionToClose !== null ? (
        <SimpleDialog
          confirm
          message={
            "Close position:" +
            (positionToClose.type == "POSITION_TYPE_BUY" ? "BUY: " : "SELL: ") +
            positionToClose.symbol +
            "#" +
            positionToClose.id +
            " # Volume = " +
            positionToClose.volume +
            " ?"
          }
          open={true}
          OKRunning={okRunning}
          close={(ok) => {
            if (ok) {
              //thuc hien close
              if (!okRunning) {
                setOKRunning(true);
                closePosition(currentConnection, positionToClose.id, () => {
                  setPositionToClose(null);
                  setOKRunning(false);
                });
              }
            } else {
              setPositionToClose(null);
            }
          }}
        />
      ) : null}
    </>
  );
}

export default withStyles({}, { withTheme: true })(AccountTradingControlPure);

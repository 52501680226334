/**Hien thi gia thi truong */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import numeral from "numeral";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SymbolPriceControl from "./SymbolPriceControl";
import TerminalControl from "./TerminalControl";
import WatchListDialog from "./WatchListDialog";
import PlaceOrderDialog from "./PlaceOrderDialog";
import OrderModifyDialog from "./OrderModifyDialog";
import PartialCloseDialog from "./PartialCloseDialog";
import SimpleDialog from "../components/SimpleDialog";
import BotInstallDialog from "./BotInstallDialog";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");
const $ = window.$; //jquery

export class MarketWatchControlPure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenSmall: window.screen.width < 500, //xac dinh man hinh phone hay may tinh
      DM_TaiKhoan: [], //danh sach tai khoan
      marketPrices: {}, //gia thi truong
      terminalList: [], //danh sach tai khoan
      activeTerminal: null, //terminal dang vao lenh
      newestTerminal: null, //terminal duoc update moi nhat
      selectedAccount: null, //so tai khoan duoc lua chon
      selectedTerminal: null,
      selectedTerminalTime: 0,
      //hien thi dialog
      showPlaceOrder: false,
      edittingOrder: false,
      positionToClosePartial: null, //position muon close 1 phan
      confirmCloseOrder: false,
      positionToClose: false,
      okRunning: false,
    };
  }
  componentDidMount() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_TaiKhoan_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        var sel = localStorage.getItem("SelectedAccount");
        var found = response.DM_TradingAccounts.find(
          (item) => item.AccountNumber == sel
        );
        this.setState({
          DM_TaiKhoan: response.DM_TradingAccounts, //danh sach tai khoan mt5 cua user
          DM_Bot: response.DM_Bot,
          selectedAccount: response.DM_TradingAccounts.length
            ? found
              ? found.AccountNumber
              : response.DM_TradingAccounts[0].AccountNumber
            : null,
        });
      })
      .catch((error) => {
        showError(error);
      });
    this.createChannel();
  }
  createChannel() {
    //tao ket noi signalR
    const that = this;
    myLib
      .useExternalScript(server.getServer() + "signalr/hubs")
      .then(() => {
        console.log("SignalR load done");
        $.connection.hub.url = server.getServer() + "signalr";

        var forexHub = $.connection.forexHub;
        //xu ly khi co thong tin prices duoc cap nhat
        forexHub.client.onMarketUpdated = function (prices) {
          that.setState({
            marketPrices: prices,
          });
        };
        forexHub.client.onError = function (ex) {
          console.log(ex);
        };
        //xu ly khi co thong tin terminal
        forexHub.client.onTerminalUpdated = function (terminal, name) {
          //console.log(terminal);

          //kiem tra xem co trong list khong
          var found = that.state.DM_TaiKhoan.find(
            (tk) => tk.AccountNumber == terminal.Account
          );
          if (found) {
            var exists = that.state.terminalList.find(
              (item) => item.Account == terminal.Account
            );
            if (!exists) {
              that.state.terminalList.push(terminal);
              that.state.terminalList = that.state.terminalList.sort((a, b) =>
                a.AccountName.localeCompare(b.AccountName)
              );
            }
            //sap xep theo symbol
            var now = new Date().getTime();
            terminal.Positions.sort((a, b) => {
              return a.symbol.localeCompare(b.symbol);
            });
            terminal.Orders.sort((a, b) => {
              return a.symbol.localeCompare(b.symbol);
            });
            if (that.state.selectedAccount == terminal.Account) {
              that.setState({
                newestTerminal: terminal,
                selectedTerminal: terminal,
                selectedTerminalTime:
                  now - that.state.selectedTerminalTime > 1000
                    ? now
                    : that.state.selectedTerminalTime,
              });
            } else {
              that.setState({
                newestTerminal: terminal,
              });
            }
          }
        };
        //cap nhat toan bo danh sach
        forexHub.client.onListTerminalUpdated = function (terminals) {
          var list = [];
          terminals.map((t) => {
            var found = that.state.DM_TaiKhoan.find(
              (tk) => tk.AccountNumber == t.Account
            );
            if (found) {
              list.push(t);
            }
          });
          list = list.sort((a, b) =>
            a.AccountName.localeCompare(b.AccountName)
          );
          that.setState({
            terminalList: list,
          });
        };
        //$.connection.hub.logging = true;
        //start proxy
        $.connection.hub
          .start()
          .done(function () {
            if (true) {
              console.log(
                "Now connected, connection ID=" + $.connection.hub.id
              );
              //truy van ngay du lieu
              forexHub.server.getMarketPrices().then((prices) =>
                this.setState({
                  marketPrices: prices,
                })
              );
            } else {
              $.connection.hub.stop();
            }
          })
          .fail(function () {
            console.log("Could not Connect!");
          });
        //when disconnect
        $.connection.hub.disconnected(function () {
          console.log("signalR disconnected");
          console.log("timer force connect");
          setTimeout(function () {
            $.connection.hub
              .start()
              .done(() => console.log("signalR reconnected"));
          }, 5000); // Restart connection after 5 seconds.
        });
        $.connection.hub.error(function (error) {
          console.log("SignalR error: " + error);
        });
      })
      .catch((e) => console.error("Khong load duoc signalr"));
  }
  componentWillUnmount() {
    //close
    try {
      console.log("stop.....");
      $.connection.hub.stop();
    } catch (e) {}
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate() {
    return !this.showBotInstall;
  }
  componentDidUpdate() {}
  doPlaceOrder(terminal, order, whenDone) {
    var forexHub = $.connection.forexHub;
    if (forexHub) {
      order.account = terminal.Account;
      forexHub.server
        .requestPlaceOrder(order)
        .then((res) => {
          if (res.RequestResult) {
            whenDone();
          } else {
            showError(res.RequestLog);
          }
        })
        .catch((e) => showError(e));
    }
  }
  modifyOrder(terminal, order, whenDone) {
    var forexHub = $.connection.forexHub;
    if (forexHub) {
      order.account = terminal.Account;
      forexHub.server
        .requestModifyOrder(order)
        .then((res) => {
          if (res.RequestResult) {
            whenDone();
          } else {
            showError(res.RequestLog);
          }
        })
        .catch((e) => showError(e));
    }
  }
  closePositionPartial(terminal, order, whenDone) {
    var forexHub = $.connection.forexHub;
    console.log(order);
    if (forexHub) {
      order.account = terminal.Account;
      forexHub.server
        .requestPartialClose(order)
        .then((res) => {
          if (res.RequestResult) {
            whenDone();
          } else {
            showError(res.RequestLog);
          }
        })
        .catch((e) => showError(e));
    }
  }
  //cancel pending order
  cancelOrder(terminal, order, whenDone) {
    var forexHub = $.connection.forexHub;
    console.log(order);
    if (forexHub) {
      order.account = terminal.Account;
      this.setState({
        okRunning: true,
      });
      forexHub.server
        .requestCancelOrder(order)
        .then((res) => {
          if (res.RequestResult) {
            whenDone();
          } else {
            showError(res.RequestLog);
          }
          this.setState({
            okRunning: false,
          });
        })
        .catch((e) => {
          showError(e);
          this.setState({
            okRunning: false,
          });
        });
    }
  }
  //close position
  closePosition(terminal, order, whenDone) {
    var forexHub = $.connection.forexHub;
    console.log(order);
    if (forexHub) {
      this.setState({
        okRunning: true,
      });
      order.account = terminal.Account;
      forexHub.server
        .requestClosePosition(order)
        .then((res) => {
          if (res.RequestResult) {
            whenDone();
          } else {
            showError(res.RequestLog);
          }
          this.setState({
            okRunning: false,
          });
        })
        .catch((e) => {
          showError(e);
          this.setState({
            okRunning: false,
          });
        });
    }
  }
  doCloseAll(terminal) {
    var forexHub = $.connection.forexHub;
    if (forexHub) {
      var order = {};
      order.account = terminal.Account;
      forexHub.server
        .requestCloseAll(order)
        .then((res) => {})
        .catch((e) => showError(e));
    }
  }
  render() {
    const list = this.state.marketPrices;
    const keys = Object.keys(list);
    const terminalList = this.state.terminalList;
    return (
      <Grid container columns={12} spacing={0.5}>
        {keys.map((k, index) => (
          <Grid item key={k} xs={this.state.screenSmall ? 6 : 2}>
            <SymbolPriceControl key={k} symbolPrice={list[k]} />
          </Grid>
        ))}
        <Grid item xs={12}></Grid>
        {terminalList.map((terminal) => (
          <Grid item key={terminal.Account} xs={this.state.screenSmall ? 6 : 2}>
            <Button
              variant={
                this.state.selectedAccount &&
                terminal.Account == this.state.selectedAccount
                  ? "contained"
                  : "outlined"
              }
              fullWidth
              onClick={() => {
                localStorage.setItem("SelectedAccount", terminal.Account);
                this.setState({
                  selectedTerminal: terminal,
                  selectedAccount: terminal.Account,
                  selectedTerminalTime: new Date().getTime(),
                });
              }}
            >
              {terminal.Account} - {terminal.AccountName}
            </Button>
          </Grid>
        ))}
        {this.state.selectedTerminal ? (
          <Grid item xs={12} key={this.state.selectedTerminal.Account}>
            <TerminalControl
              key={this.state.selectedTerminal.Account}
              terminalData={this.state.selectedTerminal}
              terminalTime={this.state.selectedTerminalTime}
              marketPrices={this.state.marketPrices}
              onAction={(button, terminal, order) => {
                if (button.ID === "PlaceOrder") {
                  this.setState({
                    activeTerminal: terminal,
                    showPlaceOrder: true,
                  });
                }
                if (button == "modify") {
                  this.setState({
                    activeTerminal: terminal,
                    edittingOrder: order,
                  });
                }
                if (button === "partial") {
                  this.setState({
                    activeTerminal: terminal,
                    positionToClosePartial: order,
                  });
                }
                if (button === "cancel") {
                  this.setState({
                    activeTerminal: terminal,
                    confirmCloseOrder: order,
                  });
                }
                if (button === "close") {
                  this.setState({
                    activeTerminal: terminal,
                    positionToClose: order,
                  });
                }
                if (button.ID === "bot") {
                  this.setState({
                    activeTerminal: terminal,
                    showBotInstall: true,
                  });
                }
              }}
            />
          </Grid>
        ) : null}
        {this.state.showPlaceOrder ? (
          <PlaceOrderDialog
            open={true}
            marketPrices={this.state.marketPrices}
            close={() => this.setState({ showPlaceOrder: false })}
            doSave={(order, whenDone) =>
              this.doPlaceOrder(this.state.activeTerminal, order, whenDone)
            }
            doCloseAll={() => {
              this.doCloseAll(this.state.activeTerminal);
            }}
          />
        ) : null}
        {this.state.edittingOrder ? (
          <OrderModifyDialog
            open={true}
            edittingOrder={this.state.edittingOrder}
            marketPrices={this.state.marketPrices}
            close={() =>
              this.setState({
                edittingOrder: null,
              })
            }
            doSave={(order, whenDone) => {
              order.ticket = this.state.edittingOrder.ticket;
              this.modifyOrder(this.state.activeTerminal, order, whenDone);
            }}
          />
        ) : null}
        {this.state.positionToClosePartial === null ? null : (
          <PartialCloseDialog
            open={true}
            edittingOrder={this.state.positionToClosePartial}
            marketPrices={this.state.marketPrices}
            close={() => {
              this.setState({
                positionToClosePartial: null,
              });
            }}
            doSave={(order, whenDone) => {
              if (
                this.state.positionToClosePartial.type == 0 ||
                this.state.positionToClosePartial.type == 1
              ) {
                order.ticket = this.state.positionToClosePartial.ticket;
                this.closePositionPartial(
                  this.state.activeTerminal,
                  order,
                  whenDone
                );
              }
            }}
          />
        )}
        {this.state.showBotInstall ? (
          <BotInstallDialog
            open={true}
            TerminalData={this.state.selectedTerminal}
            title={
              "CÀI ĐẶT BOT - " +
              this.state.selectedTerminal.AccountName +
              " [" +
              (this.state.selectedTerminal.BotList ?? "") +
              "]"
            }
            DM_Bot={this.state.DM_Bot}
            close={() => {
              this.setState({
                showBotInstall: null,
              });
            }}
            doSave={(order, whenDone) => {}}
          />
        ) : null}
        {this.state.confirmCloseOrder ? (
          <SimpleDialog
            confirm
            message={
              "Cancel limit order:" +
              this.state.confirmCloseOrder.symbol +
              "#" +
              this.state.confirmCloseOrder.ticket +
              " ?"
            }
            open={true}
            OKRunning={this.state.okRunning}
            close={(ok) => {
              if (ok) {
                //thuc hien close
                if (!this.state.okRunning) {
                  this.setState({
                    okRunning: true,
                  });
                  this.cancelOrder(
                    this.state.activeTerminal,
                    this.state.confirmCloseOrder,
                    (ok) => {
                      this.setState({
                        confirmCloseOrder: null,
                        okRunning: false,
                      });
                    }
                  );
                }
              } else {
                this.setState({
                  confirmCloseOrder: null,
                  okRunning: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.positionToClose ? (
          <SimpleDialog
            confirm
            message={
              "Close position:" +
              (this.state.positionToClose.type == 0 ? "BUY: " : "SELL: ") +
              this.state.positionToClose.symbol +
              "#" +
              this.state.positionToClose.ticket +
              " # Volume = " +
              this.state.positionToClose.volume +
              " ?"
            }
            open={true}
            OKRunning={this.state.okRunning}
            close={(ok) => {
              if (ok) {
                //thuc hien close
                if (!this.state.okRunning) {
                  this.setState({
                    okRunning: true,
                  });
                  this.closePosition(
                    this.state.activeTerminal,
                    this.state.positionToClose,
                    () => {
                      this.setState({
                        positionToClose: null,
                        okRunning: false,
                      });
                    }
                  );
                }
              } else {
                this.setState({
                  positionToClose: null,
                });
              }
            }}
          />
        ) : null}
      </Grid>
    );
  }
}
export default withStyles({}, { withTheme: true })(MarketWatchControlPure);

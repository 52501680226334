/**Hien thi gia thi truong */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import numeral from "numeral";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");

const formatNumbers = [
  "0,0.00",
  "0,0.0",
  "0,0.00",
  "0,0.000",
  "0,0.0000",
  "0,0.00000",
  "0,0.00000",
  "0,0.000000",
  "0,0.0000000",
  "0,0.00000000",
];

export default function SymbolPriceControl(props) {
  const bidRef = React.useRef(null); //tro vao bid
  const askRef = React.useRef(null);
  const [symbolPrice, setSymbolPrice] = React.useState(props.symbolPrice);
  let newPrice = props.symbolPrice;
  //kiem tra xem co su thay doi ve gia ko
  if (newPrice.bid !== symbolPrice.bid || newPrice.ask !== symbolPrice.ask) {
    if (bidRef && bidRef.current && askRef && askRef.current) {
      bidRef.current.style.backgroundColor =
        newPrice.bid !== symbolPrice.bid ? "gray" : "red";
      askRef.current.style.backgroundColor =
        newPrice.ask !== symbolPrice.ask ? "gray" : "blue";
      window.setTimeout(() => {
        if (bidRef.current) {
          bidRef.current.style.backgroundColor = "red";
        }
        if (askRef.current) {
          askRef.current.style.backgroundColor = "blue";
        }
      }, 500);
    }
    setSymbolPrice(newPrice);
  }
  return (
    <Paper
      sx={{
        paddingTop: 1,
        minWidth: "8.5rem",
        justifyContent: "center",
        margin: "0.2rem",
        height: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          minWidth: "8rem",
          backgroundColor: "gray",
          height: "50%",
          paddingTop: 1,
        }}
      >
        <Typography align="center" sx={{ fontSize: "0.7rem", color: "white" }}>
          {symbolPrice.symbol}
        </Typography>
      </Box>
      <Stack direction="row" sx={{height: "50%"}}>
        <Box
          ref={bidRef}
          sx={{
            backgroundColor: "red",
            width: "50%",
            textAlign: "center",
            paddingTop: 1
          }}
        >
          <Typography sx={{ fontSize: "0.7rem", color: "white", padding: 0.5 }}>
            {numeral(symbolPrice.bid).format(formatNumbers[symbolPrice.digits])}
          </Typography>
        </Box>
        <Box
          ref={askRef}
          sx={{
            backgroundColor: "blue",
            width: "50%",
            textAlign: "center",
            paddingTop: 1
          }}
        >
          <Typography sx={{ fontSize: "0.7rem", color: "white", padding: 0.5 }}>
            {numeral(symbolPrice.ask).format(formatNumbers[symbolPrice.digits])}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
}

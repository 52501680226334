/*Hien thi danh sach account de thuc hien trading */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Link,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
import MetaApi, { StreamingMetaApiConnectionInstance } from 'metaapi.cloud-sdk';
import AccountTradingControl from "./AccountTradingControl";
import { loadAuth2 } from "gapi-script";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

export default function TradingControl(props) {
  const[DM_TaiKhoan, setDM_TaiKhoan] = React.useState([]); //danh sach tai khoan meta
  const[saving, setSaving] = React.useState(false);
  const[realtimeNarrowToken, setRealTimeNarrowToken] = React.useState(null);

  function loadData() {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_TaiKhoan_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        setDM_TaiKhoan(response.DM_TaiKhoan);
      })
      .catch((error) => {
        setSaving(false);
        showError(error);
      });
  }
  
  React.useEffect(()=>loadData(), []); //chi thuc hien 1 lan
  console.log("so luong tai khoan", DM_TaiKhoan.length);
  return (
    <>
      {saving ? (
        <LinearProgress />
      ) : realtimeNarrowToken ? (
        <>
          {DM_TaiKhoan.map((taikhoan, index) => (
            <AccountTradingControl
              key={taikhoan.AccountId}
              Account={taikhoan}
              metaCloudAccountId={taikhoan.AccountId}
              MTClientApi = {taikhoan.MTClientApi}
              metaCloudRealtimeToken = {realtimeNarrowToken}
            />
          ))}
        </>
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </>
  );
}

import React from "react";
import { IconButton, OutlinedInput, TextField } from "@mui/material";
import NumericFormat from "react-number-format";
import ClearIcon from "@mui/icons-material/Clear";
const styles = {
  test: {
    textAlignx: {
      textAlign: "right",
    },
  },
};
export default function MyNumberEditor(props) {
  const [state, setState] = React.useState({
    value: "",
    error: false,
  });
  return (
    <NumericFormat
      fullWidth
      autoFocus={props.autoFocus}
      inputProps={{
        style: {
          textAlign: "center",
          color: props.color,
        },
        autoComplete: "off",
      }}
      label={props.label}
      thousandSeparator=","
      decimalSeparator="."
      fixedDecimalScale={true}
      allowedDecimalSeparators={[".", ","]}
      customInput={TextField}
      decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
      readOnly={props.readOnly}
      min={props.min}
      max={props.max}
      value={props.value}
      disabled={props.disabled}
      onValueChange={(value) => {
        setState({
          value: value,
          error:
            (props.min !== undefined ? value.floatValue < props.min : false) ||
            (props.max !== undefined ? value.floatValue > props.max : false),
        });
        if (props.onValueChange) {
          props.onValueChange(value.floatValue);
        }
        if (props.onChange) {
          props.onChange(value.floatValue);
        }
      }}
      error={props.error || state.error}
    />
  );
}

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

export default function MetaCloudTokenEditor(props) {
  const [saving1, setSaving1] = React.useState(false);
  const [saving2, setSaving2] = React.useState(false);
  const [test, setTest] = React.useState(null);
  const [after, setAfter] = React.useState({
    TradingAccountToken: "",
  });
  const [currentToken, setcurrentToken] = React.useState({
    NgayCN: null, //lan cap nhat cuoi
    NgayHetHan: null, //ngay het han neu co trong JWT
    KetQuaKiemTra: null,
  });
  function saveField(field, value) {
    var newAfter = {...after };
    newAfter[field] = value;
    setAfter(newAfter);
  }
  function loadData() {
    //load du lieu token hien thoi
    setSaving1(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_MetaCloud_Get",
        ThamSo: {
          TokenName: "TradingAccountToken",
        },
      })
      .then((response) => {
        var data = response.DM_MetaCloud[0];
        var jwtToken = data.JwtToken;
        var token = jwtToken ? JSON.parse(jwtToken) : null;
        var updt = data.Updt; //thoi gian cap nhat
        setSaving1(false);
        setcurrentToken({
          NgayCN: updt,
          NgayHetHan: token ? token.ValidTo : null,
        });
      })
      .catch((error) => {
        setSaving1(false);
        showError(error);
      });
  }
  function doCheck() {
    //thuc hien kiem tra token hien tai
    setSaving1(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_MetaCloud_KiemTra",
        ThamSo: {
          TokenName: "TradingAccountToken",
        },
      })
      .then((response) => {
        var list = response.DM_MetaCloud[0];
        var res = list.MetaCloudResponse;
        console.log(response);
        setSaving1(false);
        setcurrentToken({
          ...currentToken,
          KetQuaKiemTra: true,
        });
      })
      .catch((error) => {
        setSaving1(false);
        showError(error);
      });
  }
  function doSave() {
    setSaving2(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_MetaCloud_Update",
        ThamSo: {
          TokenName: "TradingAccountToken",
          TokenValue: after.TradingAccountToken,
        },
      })
      .then((response) => {
        var data = response.DM_MetaCloud[0];
        var jwtToken = data.JwtToken;
        var token = jwtToken ? JSON.parse(jwtToken) : null;
        var updt = data.Updt; //thoi gian cap nhat
        setSaving2(false);
        setcurrentToken({
          NgayCN: updt,
          NgayHetHan: token ? token.ValidTo : null,
        });
        setAfter({
          ...after,
          TradingAccountToken: "",
        });
        showSuccess("Cập nhật thành công!");
      })
      .catch((error) => {
        setSaving2(false);
        showError(error);
      });
  }
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 150,
        }}
      >
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <Typography variant="h6">
              Trạng thái token dùng cho quản trị tài khoản:
              {currentToken.NgayCN
                ? moment(currentToken.NgayCN).format("YYYY-MM-DD HH:mm:ss") +
                  " (lần cập nhật cuối)" +
                  "/ Hết hạn: " +
                  moment(currentToken.NgayHetHan).format("YYYY-MM-DD")
                : "<Chưa có>"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="medium" onClick={() => doCheck()}>
              Kiểm tra
            </Button>
          </Grid>
          <Grid item xs={12}>
            {saving1 ? (
              <CircularProgress size={24} />
            ) : currentToken.KetQuaKiemTra ? (
              "Token is OK"
            ) : null}
          </Grid>
        </Grid>
      </Paper>
      <Divider />
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 150,
        }}
      >
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <TextField
              required
              fullWidth
              label="MetaCloud Token"
              value={after.TradingAccountToken}
              onChange={(event) =>
                saveField("TradingAccountToken", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="medium"
              disabled={myLib.isEmpty(after.TradingAccountToken) || saving2}
              onClick={() => doSave()}
            >
              Cập nhật
            </Button>
          </Grid>
          <Grid item xs={12}>
            {saving2 ? <CircularProgress size={24} /> : null}
          </Grid>
        </Grid>
      </Paper>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </>
  );
}

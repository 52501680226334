import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const server = require("./lib/server");
//for test
//server.saveToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkFETUlOIiwicm9sZSI6WyJBZG1pbiIsIk5vbmUxIiwiTm9uZTIiXSwiU2Vzc2lvbklEIjoiMjE1NzUwNDEtMGFiNS00N2E4LTlhNmEtNTMwNzY4NDliMjNlIiwiVXNlclZlcnNpb24iOiIxIiwibmJmIjoxNzA2NTQ5ODg5LCJleHAiOjE3MzgxNzIyODksImlhdCI6MTcwNjU0OTg4OSwiaXNzIjoiSW5jb21wLkZvcmV4QWRtaW4iLCJhdWQiOiJJbmNvbXAuRm9yZXhBZG1pbi5XZWJBcGkifQ.zR6aa57w31b2EJlbkYIrLbA6JOB0cAx2GMzmBdQyCeM", true);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  false ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

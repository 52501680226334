import logo from "./logo.svg";
import "./App.css";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import frLocale from "date-fns/locale/vi";
import { CssBaseline } from "@mui/material";
import HomePage from "./pages/HomePage";

const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#2366c4",
    },
    secondary: {
      main: "#6C757D",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  typography: {
    body1: {
      lineHeight: 0.75,
      letterSpacing: "0.02em",
      fontSize: "0.8rem",
      fontWeight: 400,
    },
    caption: {
      lineHeight: 0.9,
    },
    body2: {
      fontSize: "0.75rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
    h4: {
      letterSpacing: "0.05em",
    },
    subtitle1: {
      lineHeight: 1.09,
    },
    overline: {
      lineHeight: 0.92,
    },
  },
  spacing: 4,
};
const componentOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontsize: 13,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 2,
          "&-MuiAccordion-root.Mui-expanded": {
            marginBottom: 2,
          },
          color: "black !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontSize: 12,
          backgroundColor: "info",
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontsize: 12,
          backgroundColor: "info",
          "&.Mui-selected": {
            backgroundColor: "#42b6f5",
          },
          padding: 6,
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontsize: 13,
          padding: 0,
        },
        input: {
          padding: 4,
          fontsize: 13,
          "&.Mui-disabled": {
            backgroundColor: "#edebeb",
          },
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-1.8vh",
        },
        shrink: {
          top: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
        },
        scroller: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 4,
          minHeight: 32,
          "&.Mui-selected": {
            color: "white",
          },
        },
        labelIcon: {
          flexDirection: "row",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAccordion: {
      styleOverrides: {
        region: {},
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 24,
          "&.Mui-expanded": {
            minHeight: 0,
          },
          "&-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 24,
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          lineHeight: 1.2,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        virtualScroller: {
          "& -webkit-.scrollbar": {
            width: "0.4em !important",
            height: "0.4em !important",
            backgroundColor: "#f1f1f1", // Change scrollbar color
          },
          "& webkit-.scrollbar-thumb": {
            backgroundColor: "#888",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        },
        root: {
          // backgroundColor: "white",
          // padding: 6,
        },
        row: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#e0f4ffa3", //màu đầu
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#ffffff", // màu 2
          },
          "&:hover, &.Mui-hovered": {
            backgroundColor: "", // hover màu
            "@media (hover: none)": {
              backgroundColor: "",
            },
          },

          "&.Mui-selected": {
            backgroundColor: "#50a6a15e", // màu khi click
            // backgroundColor: "#f6f4ff", // màu khi click

            "&:hover, &.Mui-hovered": {
              backgroundColor: "#50a6a15e", // màu khi hover
              "@media (hover: none)": {
                backgroundColor: "#50a6a15e",
              },
            },
          },
        },
        columnHeader: {
          borderRight: "1px solid rgba(224, 224, 224, 1)",
          backgroundColor: "#254881",
        },
        columnHeaderRow: {
          backgroundColor: "#75829B",
        },
        columnHeaderTitle: {
          fontSize: "12px",
          fontWeight: "600",
          fontFamily: "Roboto, Helvetica,Arial, sans-serif",
          color: "#fff",
        },
        footerContainer: {
          minHeight: "30px",
          height: "30px",
        },
      },
    },
  },
};
const theme = createTheme({ ...themeOptions, ...componentOptions });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HomePage />
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

export default function MetaCloudAccountInfo(props) {
  let account = props.accountInfo;
  let keys = Object.keys(account);
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 60,
        }}
      >
        <Stack spacing={1} direction="row">
          <IconButton aria-label="back" onClick={() => props.onBackClicked()}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography variant="h6">Tài khoản: {account.name}</Typography>
        </Stack>
      </Paper>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth1: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Thông tin</TableCell>
              <TableCell align="right">Giá trị</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((field, index) => (
              <TableRow
                key={field}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {field}
                </TableCell>
                <TableCell align="right">
                  {JSON.stringify(account[field])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        style={{ fontSize: 12, width: 750 }}
        limit={5}
      />
    </>
  );
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import Face5Icon from "@mui/icons-material/Face5";
import AddIcon from "@mui/icons-material/Add";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountActionMenu from "../components/AccountActionMenu";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SymbolSelectDialog from "./SymbolSelectDialog";
import StairsIcon from "@mui/icons-material/Stairs";
import BotsMenu from "../BotControls/BotsMenu";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SimpleDialog from "../components/SimpleDialog";

import {
  BotEditControl_DCA1,
  getThamSo_MT5_DCA1,
  extractFrom_MT5_DCA1,
  validate_ThamSo_DCA1,
} from "../BotControls/BotEditControl_DCA1";
import {
  BotEditControl_LION,
  getThamSo_MT5_LION,
  extractFrom_MT5_LION,
  validate_ThamSo_LION,
} from "../BotControls/BotEditControl_LION";
import {
  BotEditControl_DCA2,
  getThamSo_MT5_DCA2,
  extractFrom_MT5_DCA2,
  validate_ThamSo_DCA2,
} from "../BotControls/BotEditControl_DCA2";

import {
  BotEditControl_WOLF,
  getThamSo_MT5_WOLF,
  extractFrom_MT5_WOLF,
  validate_ThamSo_WOLF,
} from "../BotControls/BotEditControl_WOLF";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
var numeral = require("numeral");

function getThamSo_MT5(bot) {
  return bot.BotID == "DCA1"
    ? getThamSo_MT5_DCA1(bot)
    : bot.BotID == "LION"
    ? getThamSo_MT5_LION(bot)
    : 
    bot.BotID == "DCA2"
    ? getThamSo_MT5_DCA2(bot) :
    bot.BotID == "WOLF"
    ? getThamSo_MT5_WOLF(bot) :
    null;
}
function extractFrom_MT5(botID, thamso) {
  return botID == "DCA1"
    ? extractFrom_MT5_DCA1(thamso)
    : botID == "LION"
    ? extractFrom_MT5_LION(thamso)
    : 
    botID == "DCA2"
    ? extractFrom_MT5_DCA2(thamso)
    :botID == "WOLF"
    ? extractFrom_MT5_WOLF(thamso)
    :
    null;
}
function validate_ThamSo(bot) {
  return bot.BotID == "DCA1"
    ? validate_ThamSo_DCA1(bot)
    : bot.BotID == "LION"
    ? validate_ThamSo_LION(bot)
    : bot.BotID == "DCA2"
    ? validate_ThamSo_DCA2(bot)
    : bot.BotID == "WOLF"
    ? validate_ThamSo_WOLF(bot)

    : null;
}
const MenuBots = [
  {
    ID: "DCA1",
    Label: "DCA một chiều",
    Icon: <StairsIcon color="primary" />,
  },
  {
    ID: "1",
    Label: "-",
  },
  {
    ID: "LION",
    Label: "LION - Hedging 1 chiều",
    Icon: <RemoveRoadIcon color="primary" />,
  },
  {
    ID: "1",
    Label: "-",
  },

  {
    ID: "DCA2",
    Label: "DCA hai chiều",
    Icon: <SyncAltIcon color="primary" />,
  },
  {
    ID: "1",
    Label: "-",
  },

  {
    ID: "WOLF",
    Label: "WOLF - Đánh hai chiều",
    Icon: <SyncAltIcon color="primary" />,
  },
];

export class BotInstallDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListBots: [], //danh sach bot cua tai khoan
      activeBotIndex: -1, //bot nao dang edit
      BotLastUpdate: 0, //id de dong bo giua web va MT5
      okRunning: false, //ghi nhan trang thai dang ghi du lieu
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  componentDidMount() {
    //load danh sach BOT theo tai khoan
    this.setState({
      okRunning: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_AccountBots_GetBy",
        ThamSo: {
          AccountNumber: this.props.TerminalData.Account,
        },
      })
      .then((response) => {
        var list = [];
        var _id = new Date().getTime();
        response.DM_AccountBots.map((bot) => {
          var item = {};
          item.AccountBotID = bot.AccountBotID;
          item.BotID = bot.BotID;
          item.BotCode = bot.BotCode;
          item.BotName = bot.BotName;
          item.BotMoTa = bot.BotMoTa;
          item.BotThamSo = bot.BotThamSo;
          item.Active = bot.Active;
          item.version = 1;
          item.ConfigData = {
            ...bot,
            ...extractFrom_MT5(bot.BotID, bot.BotThamSo),
            BotCode: bot.BotID,
            Active: bot.Active,
          };
          item._id = _id++;
          list.push(item);
          this.state.BotLastUpdate =
            this.state.BotLastUpdate < bot.LastUpdate && bot.Active
              ? bot.LastUpdate
              : this.state.BotLastUpdate;
        });

        this.setState({
          okRunning: false,
          ListBots: list,
          activeBotIndex: list.length ? 0 : -1,
        });
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({
          okRunning: false,
        });
      });
  }
  componentDidUpdate() {}
  doSaveMacDinh() {
    var bot = this.state.ListBots[this.state.activeBotIndex];
    if (!validate_ThamSo(bot)) {
      alert("Tham số chưa đúng !");
      return false;
    }
    //ghi nho mac dinh
    this.setState({
      okRunning: true,
    });
    var bot = this.state.ListBots[this.state.activeBotIndex];
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Bot_Update",
        ThamSo: {
          BotID: bot.BotID,
          BotName: bot.ConfigData.BotName,
          BotMoTa: bot.ConfigData.BotMoTa,
          BotThamSo: getThamSo_MT5(bot.ConfigData),
        },
      })
      .then((response) => {
        this.setState({
          okRunning: false,
        });
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({
          okRunning: false,
        });
      });
  }
  loadMacDinh(BotID) {
    //load thong so BOT lan cuoi su dung cho tai khoan nay
    this.setState({
      okRunning: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Bot_Get",
        ThamSo: {
          BotID: BotID,
        },
      })
      .then((response) => {
        var bot = response.DM_Bot[0];
        var item = this.state.ListBots[this.state.activeBotIndex];
        item.BotName = bot.BotName;
        item.BotMoTa = bot.BotMoTa;
        item.BotThamSo = bot.BotThamSo;
        item.version++;
        item.ConfigData = {
          ...bot,
          ...extractFrom_MT5(bot.BotID, bot.BotThamSo),
          BotCode: bot.BotID,
          Active: true,
        };
        this.setState({
          okRunning: false,
          confirm_load_macdinh: false,
        });
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({
          okRunning: false,
        });
      });
  }
  saveBot() {
    //ghi nhan thong so bot vao tai khoan

    var bot = this.state.ListBots[this.state.activeBotIndex];
    var khac = null;
    this.state.ListBots.map(
      (item, index) =>
        (khac =
          item.BotCode == bot.BotCode && index != this.state.activeBotIndex
            ? item
            : khac)
    );
    if (khac) {
      alert("Trùng mã BOT");
      return false;
    }
    console.log(bot);
    if (!validate_ThamSo(bot)) {
      alert("Tham số chưa đúng !");
      return false;
    }
    this.setState({
      okRunning: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_AccountBots_Update",
        ThamSo: {
          AccountBotID: bot.AccountBotID,
          AccountNumber: this.props.TerminalData.Account,
          BotID: bot.BotID,
          BotCode: bot.ConfigData.BotCode,
          BotName: bot.ConfigData.BotName,
          BotMoTa: bot.ConfigData.BotMoTa,
          BotThamSo: getThamSo_MT5(bot.ConfigData),
          Active: bot.ConfigData.Active,
          LastUpdate: new Date().getTime(),
        },
      })
      .then((response) => {
        this.setState({
          okRunning: false,
        });
        //post dong bo
        server
          .post("Forex/ResetBotLastUpdate", {})
          .then((response) => {})
          .catch((error) => alert("Đề nghị đồng bộ BOT!"));
        this.props.close();
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({
          okRunning: false,
        });
      });
  }
  render() {
    return (
      <Dialog open={this.props.open} maxWidth={"xl"} fullWidth={true}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <Paper variant="outlined" sx={{}}>
            <Grid container spacing={1} columns={6} padding={1}>
              {this.state.ListBots.map((bot, index) => (
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    variant={
                      index == this.state.activeBotIndex
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => this.setState({ activeBotIndex: index })}
                  >
                    {bot.BotID}
                  </Button>
                </Grid>
              ))}
              <Grid item xs={1}>
                <BotsMenu
                  SubActions={MenuBots}
                  onClick={(button) => {
                    var found = this.props.DM_Bot.find(
                      (b) => b.BotID == button.ID
                    );
                    this.setState(
                      {
                        ListBots: this.state.ListBots.concat([
                          {
                            BotID: button.ID,
                            BotCode: button.ID,
                            BotName: found ? found.BotName : "",
                            BotMoTa: found ? found.BotMoTa : "",
                            BotThamSo: found ? found.BotThamSo : "",
                            version: 0, //theo theo doi va update tu form nhap
                            _id: new Date().getTime(),
                          },
                        ]),
                        activeBotIndex: this.state.ListBots.length,
                      },
                      () => this.loadMacDinh(button.ID)
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                {this.state.okRunning ? (
                  <CircularProgress size={"28px"} />
                ) : null}
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  color={
                    this.props.TerminalData.BotLastUpdate !=
                    this.state.BotLastUpdate
                      ? "error"
                      : "primary"
                  }
                  onClick={() => {
                    server
                      .post("Forex/ResetBotLastUpdate", {})
                      .then((response) => {})
                      .catch((error) => handleServerError(error));
                  }}
                >
                  Sync
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {this.state.activeBotIndex < 0 ? null : this.state.ListBots[
              this.state.activeBotIndex
            ].BotID == "DCA1" ? (
            <BotEditControl_DCA1
              key={"k" + this.state.ListBots[this.state.activeBotIndex]._id}
              BotData={this.state.ListBots[this.state.activeBotIndex]}
              onChange={(botData) => {
                var item = this.state.ListBots[this.state.activeBotIndex];
                item.BotCode = botData.BotCode;
                item.BotName = botData.BotName;
                item.BotMoTa = botData.BotMoTa;
                item.ConfigData = botData;
                item.version = botData.version;
                this.setState({
                  ListBots: this.state.ListBots,
                  changed: new Date(),
                });
              }}
            />
          ) : this.state.ListBots[this.state.activeBotIndex].BotID == "LION" ? (
            <BotEditControl_LION
              key={"k" + this.state.ListBots[this.state.activeBotIndex]._id}
              BotData={this.state.ListBots[this.state.activeBotIndex]}
              onChange={(botData) => {
                var item = this.state.ListBots[this.state.activeBotIndex];
                item.BotCode = botData.BotCode;
                item.BotName = botData.BotName;
                item.BotMoTa = botData.BotMoTa;
                item.ConfigData = botData;
                item.version = botData.version;
                this.setState({
                  ListBots: this.state.ListBots,
                  changed: new Date(),
                });
              }}
            />
          ) : this.state.ListBots[this.state.activeBotIndex].BotID == "DCA2" ? (
            <BotEditControl_DCA2
              key={"k" + this.state.ListBots[this.state.activeBotIndex]._id}
              BotData={this.state.ListBots[this.state.activeBotIndex]}
              onChange={(botData) => {
                var item = this.state.ListBots[this.state.activeBotIndex];
                item.BotCode = botData.BotCode;
                item.BotName = botData.BotName;
                item.BotMoTa = botData.BotMoTa;
                item.ConfigData = botData;
                item.version = botData.version;
                this.setState({
                  ListBots: this.state.ListBots,
                  changed: new Date(),
                });
              }}
            />
          ) : this.state.ListBots[this.state.activeBotIndex].BotID == "WOLF" ? (
            <BotEditControl_WOLF
              key={"k" + this.state.ListBots[this.state.activeBotIndex]._id}
              BotData={this.state.ListBots[this.state.activeBotIndex]}
              onChange={(botData) => {
                var item = this.state.ListBots[this.state.activeBotIndex];
                item.BotCode = botData.BotCode;
                item.BotName = botData.BotName;
                item.BotMoTa = botData.BotMoTa;
                item.ConfigData = botData;
                item.version = botData.version;
                this.setState({
                  ListBots: this.state.ListBots,
                  changed: new Date(),
                });
              }}
            />
          ) :null}
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12} padding={1}>
              <Grid item xs={2}>
                <Button
                  disabled={
                    this.state.okRunning ||
                    this.state.activeBotIndex < 0 ||
                    this.state.ListBots[this.state.activeBotIndex].ConfigData ==
                      undefined
                  }
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.saveBot();
                  }}
                >
                  OK
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  onClick={() => this.props.close(false)}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={this.state.activeBotIndex < 0}
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      confirm_load_macdinh: true,
                    });
                  }}
                >
                  Mặc định
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={
                    this.state.activeBotIndex < 0 ||
                    this.state.ListBots[this.state.activeBotIndex].ConfigData ==
                      undefined ||
                    this.state.ListBots[this.state.activeBotIndex].version <= 1
                  }
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.doSaveMacDinh();
                  }}
                >
                  Nhớ mặc định
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  disabled={this.state.activeBotIndex < 0}
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={() => {
                    this.setState({
                      confirm_xoa_bot: true,
                    });
                  }}
                >
                  Xóa
                </Button>
              </Grid>
              <Grid item xs={1}>
                {this.state.okRunning ? (
                  <CircularProgress size={"28px"} />
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        {this.state.confirm_load_macdinh ? (
          <SimpleDialog
            confirm
            message={"Lấy tham số mặc định của BOT?"}
            open={true}
            OKRunning={this.state.okRunning}
            close={(ok) => {
              if (ok) {
                //thuc hien close
                if (!this.state.okRunning) {
                  this.setState({
                    okRunning: true,
                  });
                  //load tham so
                  this.loadMacDinh(
                    this.state.ListBots[this.state.activeBotIndex].BotID
                  );
                }
              } else {
                this.setState({
                  confirm_load_macdinh: false,
                  okRunning: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.confirm_xoa_bot ? (
          <SimpleDialog
            confirm
            message={"Gỡ BOT khỏi tài khoản?"}
            open={true}
            OKRunning={this.state.okRunning}
            close={(ok) => {
              if (ok) {
                //thuc hien close
                if (!this.state.okRunning) {
                  this.setState({
                    okRunning: true,
                  });
                  //xoa bot
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_DM_AccountBots_Delete",
                      ThamSo: {
                        AccountBotID:
                          this.state.ListBots[this.state.activeBotIndex]
                            .AccountBotID,
                        AccountNumber: this.props.TerminalData.Account,
                        BotCode:
                          this.state.ListBots[this.state.activeBotIndex]
                            .BotCode,
                      },
                    })
                    .then((response) => {
                      this.state.ListBots.splice(this.state.activeBotIndex, 1);
                      this.state.activeBotIndex = this.state.ListBots.length
                        ? 0
                        : -1;
                      this.setState({
                        okRunning: false,
                        confirm_xoa_bot: false,
                      });
                      //yeu cau dong bo
                      server
                        .post("Forex/ResetBotLastUpdate", {})
                        .then((response) => {})
                        .catch((error) => alert("Đề nghị bấm lại đống bộ !"));
                    })
                    .catch((error) => {
                      handleServerError(error);
                      this.setState({
                        okRunning: false,
                      });
                    });
                }
              } else {
                this.setState({
                  confirm_xoa_bot: false,
                  okRunning: false,
                });
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}

export default withStyles({}, { withTheme: true })(BotInstallDialog);

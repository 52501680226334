/**Lua chon theo doi gia cua ma giao dich nao */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

export default function WatchListDialog(props) {
  const [saving, setSaving] = React.useState(-1);
  const pricesBySymbol = props.pricesBySymbol; //danh sach price dang update
  const symbols = props.BrokerSymbols; //tat ca symbols

  const [selectedSymbol, setSelectedSymbol] = React.useState("");
  const [listSymbols, setListSymbols] = React.useState([]); //danh sach 5 symbolds hien thi
  //loc danh sach
  function filterList() {
    let chuan = selectedSymbol ? selectedSymbol.toUpperCase() : "";
    let results = [];
    var count = 0;
    symbols.map((item, index) => {
      if (count < 5) {
        if (
          item.symbol.indexOf(chuan) >= 0 ||
          item.description.toUpperCase().indexOf(chuan) >= 0
        ) {
          results.push(item);
          count++;
        }
      }
    });
    results.map((item) => {
      item.has = pricesBySymbol[item.symbol] !== undefined;
    });
    if (results.length < 5) {
      for (var i = 0, n = results.length; i < 5 - n; i++) {
        results.push({}); //cho dong trong'
      }
    }
    return results;
  }
  function onSymbolChange(value) {
    let chuan = value ? value.toUpperCase() : "";
    setSelectedSymbol(chuan);
  }
  React.useEffect(() => {
    let list = filterList();
    setListSymbols(list);
  }, [selectedSymbol]);
  let list = listSymbols;
  list.map((item)=>item.has = pricesBySymbol[item.symbol] !== undefined);
  return (
    <Dialog open={props.open} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>Watch list</DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{}}>
          <Grid container columns={1} spacing={1} padding={1}>
            <Grid item xs={1}>
              <TextField
                label="Symbol"
                focused
                autoFocus={true}
                fullWidth
                value={selectedSymbol}
                onChange={(event) => onSymbolChange(event.target.value)}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
          }}
        >
          <Grid container columns={12} spacing={1} padding={1}>
            {list.map((item, index) => (
              <React.Fragment key={"A" + index}>
                <Grid item xs={3}>
                  <FormLabel>{item.symbol}</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <FormLabel>{item.description}</FormLabel>
                </Grid>
                <Grid item xs={1}>
                  {saving == index ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton
                      disabled={item.symbol === undefined || saving >= 0}
                      onClick={() => {
                        if (saving == -1) {
                          setSaving(index);
                          props.doWatch(item, !item.has, () => {
                            //co ket qua
                            setSaving(-1);
                          });
                        }
                      }}
                    >
                      <ThumbUpIcon
                        sx={{
                          color: item.has ? "green" : "gray",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => (props.close ? props.close() : null)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import GenerateCodeReactDialog from "../tools/GenerateCodeReactDialog";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";

import {
  filterStyles,
  loadDataError,
  handleServerError,
  loadGridColumns,
  ReactDataGrid_i18n,
} from "../components/common";
import moment from "moment";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
//kiem tra tinh hop le cua du lieu order
function validate(order, symbolPriceification, symbolPrice, oldOrder) {
  let error = {};
  if (order.volume <= 0 || order.volume > 5) {
    error.volume = true;
  }
  console.log(oldOrder);
  console.log(order);
  if (
    order.type == 2 ||
    order.type == 3
  ) {
    if (order.price == null || order.price <= 0) {
      error.price = true;
    }
  }
  if (order.type == 0) {
    //so sanh voi gia hien thoi3
    if (order.takeProfit != null && order.takeProfit !== 0 && order.takeProfit <= symbolPrice.ask) {
      error.takeProfit = true;
    }
    if (order.stopLoss != null && order.stopLoss !== 0 && order.stopLoss >= symbolPrice.bid) {
      error.stopLoss = true;
    }
  }
  if (order.type == 2) {
    if (order.price >= symbolPrice.ask) {
      error.price = true;
    }
    if (order.takeProfit != null && order.takeProfit !== 0 && order.takeProfit <= order.price) {
      error.takeProfit = true;
    }
    if (order.stopLoss != null && order.stopLoss !== 0 && order.stopLoss >= order.price) {
      error.stopLoss = true;
    }
  }
  if (order.type == 1) {
    if (order.takeProfit != null && order.takeProfit >= symbolPrice.bid) {
      error.takeProfit = true;
    }
    if (order.stopLoss != null && order.stopLoss !== 0 && order.stopLoss <= symbolPrice.ask) {
      error.stopLoss = true;
    }
  }
  if (order.type == 3) {
    if (order.price <= symbolPrice.bid) {
      error.price = true;
    }

    if (order.takeProfit != null && order.takeProfit >= order.price) {
      error.takeProfit = true;
    }
    if (order.stopLoss != null && order.stopLoss !== 0 && order.stopLoss <= order.price) {
      error.stopLoss = true;
    }
  }
  //kiem tra xem co su thay doi
  if (
    oldOrder.price === order.price &&
    oldOrder.takeProfit === order.takeProfit &&
    oldOrder.stopLoss === order.stopLoss
  ) {
    error.takeProfit = true;
    error.stopLoss = true;
    error.price = true;
  }
  return error;
}

export default function OrderModifyDialog(props) {
  const order = props.edittingOrder;
  const [validateError, setValidateError] = React.useState({});
  const selectedSymbol = order.symbol;
  const orderType = order.type;
  const volume = order.volume;
  const [price, setPrice] = React.useState(order.price);
  const [takeProfit, setTakeProfit] = React.useState(order.takeProfit);
  const [stopLoss, setStopLoss] = React.useState(order.stopLoss);
  const [comment, setComment] = React.useState(order.comment ? order.comment : "");
  function makeOrderData() {
    let order = {
      symbol: selectedSymbol,
      type: orderType,
      volume: volume,
      price: price,
      takeProfit: takeProfit,
      stopLoss: stopLoss,
      comment: comment,
    };
    return order;
  }
  let symbols = props.marketPrices ? Object.keys(props.marketPrices) : [];
  var symbolPrice =
    props.marketPrices && selectedSymbol
      ? props.marketPrices[selectedSymbol]
      : {digits: 2};
  if(!symbolPrice) {
    symbolPrice = {digits: 2};
  }
  return (
    <Dialog open={props.open} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>Modify {order.type < 2 ? "open position" : "limit order"}</DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{}}>
          <Grid container spacing={1} columns={4} padding={1}>
            <Grid item xs={4}>
              <Typography
                sx={{ flexGrow: 1, textAlign: "center", color: "blue" }}
              >
                {order.symbol}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <MyNumberEditor
                label="Bid"
                decimalScale={symbolPrice.digits}
                value={symbolPrice.bid}
                color="red"
              />
            </Grid>
            <Grid item xs={2}>
              <MyNumberEditor
                label="Ask"
                decimalScale={symbolPrice.digits}
                color="blue"
                value={symbolPrice.ask}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper variant="outlined" sx={{}}>
          <Grid container spacing={1} columns={2} padding={1}>
            <Grid item xs={2}>
              <Button fullWidth variant="outlined" color={order.type == 0 || order.type == 2 ? "primary" : "error"} >
                {order.type === 0
                  ? "BUY - INSTANT"
                  : order.type === 1
                  ? "SELL - INSTANT"
                  : order.type === 2
                  ? "BUY - LIMIT"
                  : "SELL - LIMIT"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper variant="outlined">
          <Grid container spacing={1} columns={2} padding={1}>
            <Grid item xs={1}>
              <MyNumberEditor
                label="Volume"
                value={volume}
                disabled={orderType === null}
                min={0.01}
                max={5}
                error={validateError.volume}
              />
            </Grid>
            <Grid item xs={1}>
              {orderType === 0 ||
              orderType === 1  ? null : (
                <MyNumberEditor
                  label="Price"
                  disabled={orderType === null}
                  decimalScale={symbolPrice.digits}
                  value={price}
                  onValueChange={(value) => setPrice(value)}
                  error={validateError.price}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <MyNumberEditor
                label="Take profit"
                disabled={orderType === null}
                decimalScale={symbolPrice.digits}
                value={takeProfit}
                error={validateError.takeProfit}
                onValueChange={(value) => setTakeProfit(value)}
              />
            </Grid>
            <Grid item xs={1}>
              <MyNumberEditor
                label="Stop loss"
                disabled={orderType === null}
                decimalScale={symbolPrice.digits}
                value={stopLoss}
                error={validateError.stopLoss}
                onValueChange={(value) => setStopLoss(value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Comment"
                fullWidth
                
                inputProps={{
                  maxLength: 30,
                }}
                value={comment}
                onChange={(event)=>setComment(comment)}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper variant="outlined">
          <Grid container spacing={1} columns={2} padding={1}>
            <Grid item xs={1}>
              <Button
                variant="contained"
                fullWidth
                disabled={orderType === null}
                onClick={() => {
                  let order = makeOrderData();
                  let error = validate(
                    order,
                    symbolPrice,
                    symbolPrice,
                    props.edittingOrder
                  );
                  //console.log(order, error);
                  setValidateError(error);
                  if (Object.keys(error).length > 0) {
                    console.log("Co loi");
                  } else {
                    if (props.doSave) {
                      props.doSave(order, () =>
                        props.close ? props.close() : ""
                      );
                    }
                  }
                }}
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                fullWidth
                color="secondary"
                onClick={() => props.close(false)}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import Face5Icon from "@mui/icons-material/Face5";
import AddIcon from "@mui/icons-material/Add";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountActionMenu from "../components/AccountActionMenu";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SymbolSelectDialog from "./SymbolSelectDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
var numeral = require("numeral");

const listActions = [
  {
    ID: "PlaceOrder",
    Label: "Place Order",
    Icon: <CurrencyExchangeIcon color="error" />,
  },
  {
    ID: "Divider1",
    Label: "-",
  },
  {
    ID: "Symbols",
    Label: "List of symbols",
    Icon: <PriceChangeIcon />,
  },
];

export class TerminalStateControlPure extends React.Component {
  constructor(props) {
    super(props);
    this.equityRef = React.createRef(null);
    this.state = {
      screenSmall: window.screen.width < 500,
      terminalData: props.terminalData,
      openPositons: props.terminalData.Positions,
      pendingOrders: props.terminalData.Orders,
      totalProfit: null,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let terminalData = nextProps.terminalData;
    let openPositons = terminalData.Positions; //danh sach lenh dang open
    let pendingOrders = terminalData.Orders; //danh sach lenh cho
    var total = 0;
    openPositons.map((p) => (total += p.profit));
    total = numeral(total).format("0,0.00") + " $";
    return {
      terminalData: terminalData,
      openPositons: terminalData.Positions,
      pendingOrders: terminalData.Orders,
      totalProfit: total,
    };
  }
  componentDidUpdate() {
    if (this.equityRef && this.equityRef.current) {
      this.equityRef.current.style.backgroundColor = "#ffc2b3";
      window.setTimeout(() => {
        if (this.equityRef.current) {
          this.equityRef.current.style.backgroundColor = "";
        }
      }, 500);
    }
  }
  render() {
    return this.state.screenSmall ? (
      <Grid container columns={6} spacing={1}>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography>
              <b>
                {this.state.terminalData.AccountName +
                  (this.state.terminalData.BotList != null
                    ? " [" +
                      (this.state.terminalData.BotList.length
                        ? this.state.terminalData.BotList.substring(
                            0,
                            this.state.terminalData.BotList.length - 1
                          )
                        : "") +
                      "]"
                    : "")}
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            ref={this.equityRef}
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Equity:{" "}
              <b>
                {numeral(this.state.terminalData.Equity).format("0,0.00") +
                  " $"}
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={(button) => {
              this.props.onAction({ ID: "PlaceOrder" });
            }}
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={(button) => {
              this.props.onAction({ ID: "bot" });
            }}
          >
            <Face5Icon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    ) : (
      <Grid container columns={12}>
        <Grid item xs={1}>
          <Tooltip
            title={
              this.state.terminalData.Mode === "demo"
                ? "Demo account"
                : this.state.terminalData.Mode === "contest"
                ? "Contest account"
                : "Real account"
            }
            arrow
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
                color: "black",
              }}
            >
              <Typography>
                {this.state.terminalData.Mode === "demo"
                  ? "D"
                  : this.state.terminalData.Mode === "contest"
                  ? "C"
                  : "$"}
              </Typography>
            </Avatar>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {this.state.terminalData.Conpany +
                  "\n" +
                  this.state.terminalData.Server}
              </div>
            }
            arrow
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>
                  {this.state.terminalData.AccountName +
                    (this.state.terminalData.BotList != null
                      ? " [" +
                        (this.state.terminalData.BotList.length
                          ? this.state.terminalData.BotList.substring(
                              0,
                              this.state.terminalData.BotList.length - 1
                            )
                          : "") +
                        "]"
                      : "")}
                </b>
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        
        <Grid item xs={2}>
          <Box
            ref={this.equityRef}
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Equity:{" "}
              <b>
                {numeral(this.state.terminalData.Equity).format("0,0.00") +
                  " $"}
              </b>
            </Typography>
          </Box>
        </Grid>
        
        <Grid item xs={1}>
          <IconButton
            onClick={(button) => {
              this.props.onAction({ ID: "PlaceOrder" });
            }}
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={(button) => {
              this.props.onAction({ ID: "bot" });
            }}
          >
            <Face5Icon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles({}, { withTheme: true })(TerminalStateControlPure);

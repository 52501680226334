import { showError } from "../components/common";
import moment from "moment";

function createClientID(order) {
  return "TS_" + order.symbol + "_" + moment(new Date()).format("DDHHmmss"); //clientId must follow ${strategyId}_${positionId}_${orderId}
}
export function doPlaceOrder(currentConnection, order, whenDone) {
  try {
    console.log(currentConnection, order);
    return;
    if (currentConnection) {
      //thuc hien tao order
      if (order.type == "ORDER_TYPE_BUY") {
        currentConnection
          .createMarketBuyOrder(
            order.symbol,
            order.volume,
            order.stopLoss ? order.stopLoss : 0,
            order.takeProfit ? order.takeProfit : 0,
            {
              comment: order.comment,
              clientId: createClientID(order),
            }
          )
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      }
      //lenh sell
      if (order.type == "ORDER_TYPE_SELL") {
        currentConnection
          .createMarketSellOrder(
            order.symbol,
            order.volume,
            order.stopLoss ? order.stopLoss : 0,
            order.takeProfit ? order.takeProfit : 0,
            {
              comment: order.comment,
              clientId: createClientID(order),
            }
          )
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      }
      //lenh buy limit
      if (order.type == "ORDER_TYPE_BUY_LIMIT") {
        currentConnection
          .createLimitBuyOrder(
            order.symbol,
            order.volume,
            order.price,
            order.stopLoss ? order.stopLoss : 0,
            order.takeProfit ? order.takeProfit : 0,
            {
              comment: order.comment,
              clientId: createClientID(order),
            }
          )
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      }
      //lenh sell limit
      if (order.type == "ORDER_TYPE_SELL_LIMIT") {
        currentConnection
          .createLimitSellOrder(
            order.symbol,
            order.volume,
            order.price,
            order.stopLoss ? order.stopLoss : 0,
            order.takeProfit ? order.takeProfit : 0,
            {
              comment: order.comment,
              clientId: createClientID(order),
            }
          )
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      }
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
//thuc hien cancel pedning order
export function doCancelOrder(currentConnection, order, whenDone) {
  try {
    if (currentConnection) {
      currentConnection
        .cancelOrder(order.id)
        .then((result) => {
          console.log(result);
          whenDone(true);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
          whenDone(false);
        });
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
//thuc hien dong' tat ca cac lenh
export function doCloseAll(currentConnection) {
  try {
    if (currentConnection) {
      currentConnection
        .closeAll()
        .then((result) => {
          console.log(result);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
        });
    }
  } catch (e) {
    showError(e);
  }
}
//thuc hien dang ky hoac huy dang ky theo doi gia' bien dong
export function doRegisterWatch(currentConnection, symbol, register, whenDone) {
  try {
    if (currentConnection) {
      if (register) {
        console.log("add.......");
        currentConnection

          .subscribeToMarketData(symbol.symbol, [
            {
              type: "quotes", //lay thong tin gia'
            },
          ])
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      } else {
        console.log("unscurox.........");
        currentConnection

          .unsubscribeFromMarketData(symbol.symbol, [
            {
              type: "quotes", //lay thong tin gia'
            },
          ])
          .then((result) => {
            console.log(result);
            whenDone(true);
          })
          .catch((e) => {
            console.log(e);
            showError(e);
            whenDone(false);
          });
      }
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
//modify opening position
export function modifyPosition(
  currentConnection,
  positionId,
  stopLoss,
  takeProfit,
  whenDone
) {
  try {
    if (currentConnection) {
      currentConnection

        .modifyPosition(positionId, stopLoss, takeProfit)
        .then((result) => {
          console.log(result);
          whenDone(true);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
          whenDone(false);
        });
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
//modify opening position
export function modifyPendingOrder(
  currentConnection,
  orderId,
  price,
  stopLoss,
  takeProfit,
  whenDone
) {
  try {
    if (currentConnection) {
      currentConnection

        .modifyOrder(orderId, price, stopLoss, takeProfit)
        .then((result) => {
          console.log(result);
          whenDone(true);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
          whenDone(false);
        });
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
export function closePosition(currentConnection, positionId, whenDone) {
  try {
    if (currentConnection) {
      currentConnection

        .closePosition(positionId)
        .then((result) => {
          console.log(result);
          whenDone(true);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
          whenDone(false);
        });
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}
export function closePositionPartial(
  currentConnection,
  positionId,
  volume,
  whenDone
) {
  try {
    if (currentConnection) {
      currentConnection

        .closePositionPartially(positionId, volume)
        .then((result) => {
          console.log(result);
          whenDone(true);
        })
        .catch((e) => {
          console.log(e);
          showError(e);
          whenDone(false);
        });
    }
  } catch (e) {
    showError(e);
    whenDone(false);
  }
}

/*Control hien thi tai khoan va trang thai tai khoan online thong qua ket noi websocket voi metacloud */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import numeral from "numeral";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ClearIcon from "@mui/icons-material/Clear";
import SimpleDialog from "../components/SimpleDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const formatNumbers = [
  "0,0.00",
  "0,0.0",
  "0,0.00",
  "0,0.000",
  "0,0.0000",
  "0,0.00000",
  "0,0.00000",
  "0,0.000000",
  "0,0.0000000",
  "0,0.00000000",
];

export default function OpeningOrderControl(props) {
  const [confirmCloseOrder, setConfirmCloseOrder] = React.useState(false);
  const profitRef = React.useRef(null);
  const [lastProfit, setLastProfit] = React.useState(
    props.TradePosition.profit
  );
  const [profitChanged, setProfitChanged] = React.useState(false);
  if (lastProfit != props.TradePosition.profit) {
    setLastProfit(props.TradePosition.profit);
  }
  var symbolPrice = props.marketPrices
    ? props.marketPrices[props.TradePosition.symbol]
    : null;
  if (!symbolPrice) {
    symbolPrice = { digits: 3 };
  }
  return (
    <Paper variant="outlined" sx={{padding: 0}}>
      <Grid container columns={9} spacing={1}>
        <Grid item xs={1}>
          <Avatar
            sx={{
              width: 24,
              height: 24,
              margin: 1,
              bgcolor: props.TradePosition.type === 0 ? "green" : "red",
            }}
          >
            <Typography fontSize={11}>
              {props.TradePosition.type === 0 ? "B" : "S"}
            </Typography>
          </Avatar>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="text"
              fullWidth
              onClick={() => (props.doAction ? props.doAction("modify") : null)}
            >
              {myLib.isEmpty(props.TradePosition.comment) ? props.TradePosition.symbol : props.TradePosition.comment}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <Tooltip
            title={numeral(props.TradePosition.price).format(
              formatNumbers[symbolPrice.digits]
            )}
            arrow
          >
            <Typography>{props.TradePosition.volume}</Typography>
          </Tooltip>
            
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            ref={profitRef}
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              color: props.TradePosition.profit >= 0 ? "blue" : "red",
            }}
          >
            <Typography>
              {numeral(props.TradePosition.profit).format("+0,0.00")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title={numeral(props.TradePosition.takeProfit).format(
              formatNumbers[symbolPrice.digits]
            )}
            arrow
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
                bgcolor: props.TradePosition.takeProfit ? "blue" : "gray",
              }}
            >
              <Button
                sx={{ color: "white", fontWeight: "small" }}
                onClick={() =>
                  props.doAction ? props.doAction("partial") : null
                }
              >
                TP
              </Button>
            </Avatar>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title={numeral(props.TradePosition.stopLoss).format(
              formatNumbers[symbolPrice.digits]
            )}
            arrow
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                margin: 1,
                bgcolor: props.TradePosition.stopLoss ? "blue" : "gray",
              }}
            >
              <Button
                sx={{ color: "white", fontWeight: "small" }}
                onClick={() =>
                  props.doAction ? props.doAction("partial") : null
                }
              >
                SL
              </Button>
            </Avatar>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => (props.doAction ? props.doAction("close") : null)}
          >
            <ClearIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
